import { animated, useSpring } from "@react-spring/web";
import Big from "big.js";
import { FunctionalComponent } from "preact";

import { clamp } from "@/utils/numbers";

import "./style.css";

import { useSetAtom } from "jotai";
import { useEffect } from "preact/hooks";

import { inputMinPrecisionAtom } from "@/components/StakeAmount";
import { CustomComponent } from "@/types/props";

type SliderProps = CustomComponent<
  {
    value: Big;
    setValue: (newValue: Big) => void;
    min: Big;
    max: Big;
    step: Big;
  },
  "div"
>;

const calculateRemSize = (): number =>
  parseFloat(getComputedStyle(document.documentElement).fontSize);

export const Slider: FunctionalComponent<SliderProps> = (
  { value, setValue, min, max, step, onTouchStart, ...rest },
) => {
  const setMinPrecision = useSetAtom(inputMinPrecisionAtom);
  const [props, api] = useSpring(() => ({
    from: {
      x: 0,
    },
    config: {
      tension: 210,
      friction: 20,
    },
  }));

  if (max.lt(min)) {
    min = Big(0);
  }

  if (max.eq(min)) {
    max = min.add(0.01);
  }

  const range = max.sub(min);
  const offsetValue = value.sub(min);
  let fraction = clamp(0, offsetValue.div(range), 1);

  if (fraction.gt(0.99) && fraction.lt(1)) {
    value = max;
    fraction = Big(1);
  }

  useEffect(() => {
    api.start({
      to: { x: fraction.toNumber() },
    });
  }, [fraction]);

  const handleTouch = (e: TouchEvent) => {
    const touch = e.touches[0];

    if (!touch) {
      return;
    }

    const target = touch.target as HTMLInputElement;

    const thumbSize = 2.5 * calculateRemSize();

    const k = clamp(
      0,
      Big((touch.clientX - thumbSize) / (target.clientWidth - thumbSize)),
      1,
    );

    setValue(range.mul(k).add(min));
  };

  return (
    <div
      class="slider"
      {...rest}
      onClick={() => setMinPrecision(2)}
      onTouchStart={(e) => {
        onTouchStart && onTouchStart(e);
        handleTouch(e);
      }}
      onTouchMove={(e) => {
        handleTouch(e);
      }}
    >
      <input
        type="range"
        min={min.toNumber()}
        max={max.toNumber()}
        value={value.toNumber()}
        onChange={(e) => setValue(Big(e.currentTarget.value))}
        step={step.toNumber()}
      />
      <animated.div
        className="slider-progress"
        style={{
          width: props.x.to(
            (x) => `calc(var(--thumb-size) * ${1 - x} + 100% * ${x})`,
          ),
        }}
      >
        <div className="slider-thumb" />
      </animated.div>
    </div>
  );
};
