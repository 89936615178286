export const CloseAltIcon = (
  <svg
    width="36"
    height="36"
    viewBox="-2 -0.5 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-closealt"
  >
    <path
      d="M2.14258 13.3999C1.77441 13.0381 1.78711 12.397 2.12988 12.0542L5.76074 8.42334L2.12988 4.80517C1.78711 4.45605 1.77441 3.82129 2.14258 3.45312C2.51074 3.07861 3.15186 3.09131 3.49463 3.44043L7.11914 7.06494L10.7437 3.44043C11.0991 3.08496 11.7212 3.08496 12.0894 3.45312C12.4639 3.82129 12.4639 4.44336 12.1021 4.80517L8.48389 8.42334L12.1021 12.0478C12.4639 12.4097 12.4575 13.0254 12.0894 13.3999C11.7275 13.7681 11.0991 13.7681 10.7437 13.4126L7.11914 9.78808L3.49463 13.4126C3.15186 13.7617 2.51709 13.7681 2.14258 13.3999Z"
      fill="currentColor"
    />
  </svg>
);
