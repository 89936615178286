import clsx from "clsx";
import { ComponentProps, FunctionalComponent } from "preact";

import { ClassNamed } from "@/types/classname";

type LinkProps = ClassNamed<ComponentProps<"a">>;

export const Link: FunctionalComponent<LinkProps> = (
  { className, href, ...props },
) => {
  if (href) {
    href = href as string;
    if (href.startsWith("http") && !props.target) {
      props.target = "_blank";
    }
  }

  return <a className={clsx(className)} href={href} {...props} />;
};
