import clsx from "clsx";
import { FunctionalComponent } from "preact";

import { ClassNamed } from "@/types/classname";

import "./style.css";

import { IconKey } from "./sprite";

type IconProps = ClassNamed<{
  iconKey: IconKey;
}>;

export const Icon: FunctionalComponent<IconProps> = ({ iconKey }) => {
  return (
    <svg className={clsx("icon", `icon-${iconKey}`)} viewBox="0 0 36 36">
      <use data-icon={iconKey} href={`#icon-${iconKey}`} />
    </svg>
  );
};

export { IconSprite } from "./sprite";
