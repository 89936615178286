export const LightningIcon = (
  <svg
    width="36"
    height="36"
    viewBox="-2.5 -0.5 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-lightning"
  >
    <path
      d="M1.79639 9.18506C1.79639 9.02637 1.85986 8.88672 1.98047 8.74072L8.02979 1.30762C8.50586 0.723632 9.25488 1.10449 8.98193 1.82812L7.01416 7.07129H10.7656C11.0576 7.07129 11.2734 7.27441 11.2734 7.55371C11.2734 7.70605 11.21 7.8457 11.0894 7.99805L5.04004 15.4248C4.56396 16.0088 3.81494 15.6279 4.08789 14.9106L6.05566 9.66113H2.3042C2.01221 9.66113 1.79639 9.45801 1.79639 9.18506Z"
      fill="currentColor"
    />
  </svg>
);
