import { getInlineFormat } from "../CurrencyFormat";
import Big from "big.js";
import { atom, useAtom } from "jotai";
import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";

import { InputCard } from "@/components";

import { focusAtom } from "@/components/StakeAmount";
import { useAccount } from "@/hooks/useAccount";

type EarnAmountProps = {};

export const earnAmountAtom = atom(Big(1000));

export const EarnAmount: FunctionalComponent<EarnAmountProps> = ({}) => {
  const focusControls = useAtom(focusAtom);

  const account = useAccount();
  const max = account.connected ? account.tsTONBalance : Big(1000);

  const [value, setValue] = useAtom(earnAmountAtom);

  useEffect(() => {
    if (max.eq(0)) {
      return;
    }
    setValue(max);
  }, [max.toString()]);

  const inputFormat = getInlineFormat({
    amount: value,
    base: "tsTON",
    tonSuffix: false,
  });

  return (
    <InputCard
      action="re-invest"
      error=""
      errorVisible={false}
      focusControls={focusControls}
      inputFormat={inputFormat}
      setValue={setValue}
      showSlider={true}
      sliderRange={[Big(0), max]}
      value={value}
      setMax={() => setValue(max)}
      sliderStep={Big(0.01)}
    />
  );
};
