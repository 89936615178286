import Big from "big.js";
import { atom } from "jotai";

const zero = Big(0);

export const totalValueLockedAtom = atom<Big>(zero);
export const stakersCountAtom = atom<Big>(zero);
export const currentApyAtom = atom<Big>(zero);
export const historicalApyAtom = atom<Big>(zero);
export const roundRoiAtom = atom<Big>(zero);
export const prevRoundRoiAtom = atom<Big>(zero);
export const cycleEndAtom = atom<number>(0);
