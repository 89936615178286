import Big from "big.js";
import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { Heading, Link, Text, TextButton } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { Action } from "@/types/action";
import { ModalControls } from "@/uikit/Modal";
// import { StakeSuccessModal } from "./stakeSuccessModal";
import { explorerURL } from "@/utils/address";
import { formatNumber } from "@/utils/numbers";

import { StakeNFTCard } from "./nftCard";

type ResultModalProps = {
  success: boolean;
  amount: Big;
  action: Action | "deposit" | "withdraw" | "claimRewards";
  bestRate: boolean;
  controls: ModalControls;
  token?: string;
};

export const ResultModal: FunctionalComponent<ResultModalProps> = (
  { success, amount, action, controls, bestRate, token = "TON" },
) => {
  const account = useAccount();

  if (!account.connected) {
    return <></>;
  }

  // if (action === "stake" && success) {
  //   return <StakeSuccessModal amount={amount} controls={controls} />;
  // }

  const description = (
    <Text className="description">
      {success ? (
        <>
          Your{" "}
          <Text embed weight="semibold">
            {formatNumber(amount)} {token}
          </Text>{" "}
          has been sent to{" "}
          {action === "stake" || action === "deposit"
            ? "staking contract"
            : "your wallet"}
          . Please check your account to see the updated balance.
        </>
      ) : (
        "An error has occured"
      )}
      {success ? (
        <span>
          <br />
          <br />
          <Link
            href={explorerURL(account.address)}
            className="explorer-link"
            data-type="secondary"
          >
            View explorer
          </Link>
        </span>
      ) : undefined}
    </Text>
  );

  return (
    <>
      <div
        className="stake-modal-result-header gap-vertical"
        data-success={success}
      >
        <div className="stake-modal-result-icon">
          <Icon iconKey={success ? "tick" : "close"} />
        </div>
        <Heading level={1}>{success ? "Success" : "Error"}</Heading>
        {description}
      </div>
      {action === "unstake" && bestRate && success ? (
        <StakeNFTCard />
      ) : undefined}
      <div className="stake-modal-result-buttons gap-vertical">
        <TextButton onClick={controls.close}>Done</TextButton>
      </div>
    </>
  );
};
