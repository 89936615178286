export const TonLogo = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-tonlogo"
  >
    <path
      d="M12.3941 0.333374H2.93938C1.2017 0.333374 0.0991498 2.20923 0.973426 3.72404L6.80918 13.8378C7.19042 14.4986 8.14389 14.4986 8.52513 13.8378L14.3609 3.72404C15.2344 2.21 14.1326 0.333374 12.3949 0.333374L12.3941 0.333374ZM6.80375 10.6203V10.8059L5.53271 8.34613L2.4673 2.86143C2.26465 2.51048 2.51544 2.06015 2.93861 2.06015H6.80297V10.6211L6.80375 10.6203ZM12.8654 2.86143L9.79927 8.34768L8.52901 10.8059V10.6203V9.55425V2.05938H12.3934C12.8173 2.05938 13.0673 2.50971 12.8647 2.86065L12.8654 2.86143Z"
      fill="currentColor"
    />
  </svg>
);
