import { FunctionalComponent } from "preact";

import { InfoCard } from "@/uikit";

import "./style.css";

import { FAQPoint } from "./point";
import { faqPoints, FAQType } from "./points";

type FAQProps = {
  type?: FAQType;
};

export const FAQ: FunctionalComponent<FAQProps> = ({ type = "general" }) => {
  return (
    <InfoCard title="FAQ" sectionKey="faq">
      {faqPoints[type].map((point) => (
        <FAQPoint key={point.title} {...point} />
      ))}
    </InfoCard>
  );
};
