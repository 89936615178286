import Big from "big.js";
import { FunctionalComponent } from "preact";

import { CurrencyFormat } from "@/components";
import { Caption, Heading, Highlight, Loader } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import { Action } from "@/types/action";
import { formatNumber } from "@/utils/numbers";

type CalculatorHeadingProps = {
  action: Action;
  stakeAmount: Big;
};

export const CalculatorHeading: FunctionalComponent<CalculatorHeadingProps> = (
  { action, stakeAmount },
) => {
  const currency = useCurrency();
  const earnings = currency.getProjectedEarnings(stakeAmount);

  const yearlyEarn = currency.fromTON(earnings.yearly);
  const isYearlyEarnVisible =
    (currency.loaded || currency.base === "TON") &&
    (stakeAmount.eq(0) || yearlyEarn.gt(0));

  const yearlyEarnVisual = isYearlyEarnVisible ? yearlyEarn : Big(234.56);

  const yearlyHighlight = (
    <Highlight>
      <CurrencyFormat amount={yearlyEarn}>
        <Loader checkValue={isYearlyEarnVisible}>
          {formatNumber(yearlyEarnVisual, true, 2, false)}
        </Loader>
      </CurrencyFormat>
    </Highlight>
  );

  const main = (
    <Heading level={1}>
      {action === "stake" ? (
        <>
          You could earn
          <br />
          {yearlyHighlight}&nbsp;yearly
        </>
      ) : (
        <>
          Your potential rewards
          <br />
          {yearlyHighlight}&nbsp;yearly
        </>
      )}
    </Heading>
  );

  let subtitle;

  if (action === "stake") {
    const quarterlyEarn = currency.fromTON(earnings.quarterly);

    const quarterlyEarnVisual = isYearlyEarnVisible
      ? quarterlyEarn
      : Big(34.56);

    subtitle = (
      <>
        It's estimated at{" "}
        <CurrencyFormat amount={quarterlyEarn}>
          <Loader checkValue={isYearlyEarnVisible}>
            {formatNumber(quarterlyEarnVisual, true, 2, false)}
          </Loader>
        </CurrencyFormat>{" "}
        in 3 months.
      </>
    );
  } else {
    subtitle = "Based on your current staked balance.";
  }

  return (
    <>
      {main}
      <Caption>{subtitle}</Caption>
    </>
  );
};
