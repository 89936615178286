import { FarmData } from "@/hooks/useFarmingAccount";

import "./style.css";

import Big from "big.js";
import { atom, useAtom } from "jotai";
import { ComponentChild, FunctionalComponent } from "preact";
import { useEffect, useRef } from "preact/hooks";

import { AmountError, CurrencyInput, MaxButton } from "@/components";
import { Heading, Text } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";

import "./style.css";

import { useInlineFormat } from "@/components/CurrencyFormat";
import { ERROR_MESSAGES } from "@/constants/errorMessages";

type FarmingInputProps = {
  farm: FarmData;
  value: Big;
  setValue: (newValue: Big) => void;
  balance: Big;
  isOpen?: boolean;
};

export const focusAtom = atom<boolean>(true);

export const FarmingInput: FunctionalComponent<FarmingInputProps> = (
  { farm, value, setValue, balance, isOpen },
) => {
  const account = useAccount();
  const [focus, setFocusRaw] = useAtom(focusAtom);
  const focusLock = useRef(false);
  const inputRef = useRef<HTMLSpanElement>(null);

  const setFocus = (value: boolean) => {
    if (focusLock.current) {
      return;
    }

    if (!focus && value) {
      inputRef.current?.focus();
    }

    if (focus && !value) {
      inputRef.current?.blur();
    }

    setFocusRaw(value);
  };

  const lockFocus = () => {
    focusLock.current = true;
  };

  const releaseFocus = () => {
    focusLock.current = false;
  };

  const addFocus = () => {
    setFocus(true);
  };

  const removeFocus = () => {
    setFocus(false);
  };

  const addLockFocus = () => {
    addFocus();
    lockFocus();
  };

  useEffect(() => {
    if (isOpen === undefined) {
      return;
    }

    const input = inputRef.current;

    if (!input) {
      return;
    }

    setTimeout(() => {
      if (isOpen) {
        addFocus();
        input.scrollIntoView({ block: "center" });
      } else {
        removeFocus();
      }
    }, 500);
  }, [isOpen]);

  const valueProps = {
    value,
    setValue,
  };

  const inputFormat = useInlineFormat({
    amount: valueProps.value,
    tonSuffix: false,
    base: farm.token.name,
  });

  const display = (
    <div className="stake-input">
      <label
        {...inputFormat}
        onMouseDown={(e) => {
          if (e.target !== e.currentTarget) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CurrencyInput inputRef={inputRef} {...valueProps} />
      </label>
    </div>
  );

  let maxButton: ComponentChild = undefined;

  if (account.connected) {
    maxButton = <MaxButton setMax={() => setValue(balance)} />;
  }

  let errorMessage = "";

  if (value.lte(0)) {
    errorMessage = ERROR_MESSAGES.MIN_DEPOSIT(farm.token.name);
  } else if (value.gt(balance)) {
    errorMessage = ERROR_MESSAGES.NOT_ENOUGH_DEPOSIT(farm.token.name);
  }

  return (
    <div
      className="farming-input"
      data-focused={focus}
      onMouseDown={addLockFocus}
      onMouseUp={releaseFocus}
      onTouchStart={addLockFocus}
      onTouchEnd={() => {
        releaseFocus();
        removeFocus();
      }}
      onClick={addFocus}
      onBlur={removeFocus}
    >
      <div>
        {maxButton}
        <Text variant="body" className="stake-amount-action">
          You deposit
        </Text>
        <Heading level={1}>{display}</Heading>
        <AmountError
          message={errorMessage || ""}
          visible={!!errorMessage && account.connected}
        />
      </div>
    </div>
  );
};
