import Big from "big.js";
import { atom, useAtom, useSetAtom, WritableAtom } from "jotai";
import { ComponentChildren, FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import {
  PromoCard,
  Reward,
  StakeAmount,
  StakeButton,
  StakingInfo,
  Terms,
} from "@/components";
import { Highlight, Link, Text } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { useCurrency } from "@/hooks/useCurrency";
import { pageAtom } from "@/state/page";
import { Action } from "@/types/action";
import { clamp, formatAmount } from "@/utils/numbers";

import "./style.css";

// import { historicalApyAtom } from "@/state/api/blockchainData";

// import { BoostCard } from "./boostCard";
import { CalculatorHeading } from "./heading";

type CalculatorProps = {
  action: Action;
};

const stakeAmountAtom = atom(Big(1000));
const unstakeAmountAtom = atom(Big(1000));

const amountAtoms: Record<Action, WritableAtom<Big, [Big], void>> = {
  stake: stakeAmountAtom,
  unstake: unstakeAmountAtom,
};

export const Calculator: FunctionalComponent<CalculatorProps> = (
  { action },
) => {
  const currency = useCurrency();
  const [stakeAmount, setStakeAmount] = useAtom(amountAtoms[action]);
  const account = useAccount();
  const setPage = useSetAtom(pageAtom);
  const [loadedAtoms, setLoadedAtoms] = useState<Set<Action>>(new Set());
  // const apy = useAtomValue(historicalApyAtom);

  const minString = account.min[action].toString();

  useEffect(() => {
    if (account.connected) {
      return;
    }

    if (stakeAmount.gt(0)) {
      return;
    }

    if (currency.base === "TON") {
      return setStakeAmount(Big(1000));
    }

    if (!currency.loaded) {
      return;
    }

    setLoadedAtoms(new Set([...loadedAtoms, action]));

    setStakeAmount(currency.toTON(Big(10000)));
  }, [account.connected, minString, action, currency.base, currency.loaded]);

  useEffect(() => {
    if (!account.connected) {
      return;
    }

    if (account.isLoading) {
      return;
    }

    setLoadedAtoms(new Set([...loadedAtoms, action]));

    setStakeAmount(account.max[action]);
  }, [
    account.isLoading,
    account.connected,
    account.connected && account.max[action].toString(),
    minString,
    action,
  ]);

  const isUnstake = action === "unstake";

  let promoCards: ComponentChildren[] = [];

  if (account.connected) {
    if (
      account.userStaked.gt(0) &&
      account.userStaked.lt(account.userBalance) &&
      account.userBalance.gt(account.min.stake)
    ) {
      const toStake = clamp(account.min.stake, account.userStaked);
      const toStakeFormatted = formatAmount({
        amount: toStake,
        fracDigits: 3,
        stripZeros: true,
      });

      promoCards.push(
        <PromoCard variant="purple" key="double-rewards">
          <Text variant="h3" weight="semibold">
            Double your potential rewards by staking{" "}
            <Highlight>{toStakeFormatted}</Highlight> to your account.
          </Text>
          <Text>
            <Link
              onClick={() => {
                setPage("stake");
                setStakeAmount(toStake);
              }}
            >
              Stake more -{">"}
            </Link>
          </Text>
        </PromoCard>,
      );
    }

    if (account.userStaked.gt(500000)) {
      promoCards.push(
        <PromoCard variant="yellow" key="exclusive">
          <Text className="text-gradient" variant="h3" weight="semibold">
            Exclusive Privileges
          </Text>
          <Text variant="subtitle">
            With your current stake you are qualified to Preferred Status, where
            exclusive privileges await. Discover personalized terms and elite
            services, tailored for you. Please contact your Personalized Manager
            for unique experience.
          </Text>
          <Link
            href="https://t.me/malev"
            className="button"
            data-variant="primary"
          >
            <Text variant="h4" weight="semibold">
              Open Telegram
            </Text>
          </Link>
        </PromoCard>,
      );
    }
  }

  const headingAmount =
    account.connected && isUnstake
      ? account.max[action].sub(stakeAmount)
      : stakeAmount;

  let available: Big | undefined = undefined;

  if (!isUnstake && account.connected) {
    if (account.isLoading) {
      available = Big(123456).div(100);
    } else {
      available = account.userBalance;
    }
  }

  const earnings = account.connected
    ? currency.getProjectedEarnings(account.userStaked).perRound
    : Big(0);

  return (
    <div className="calculator gap-vertical">
      <CalculatorHeading
        action={action}
        stakeAmount={clamp(0, headingAmount)}
      />
      {promoCards}
      {earnings.gt(1e-7) ? <Reward next={earnings} /> : undefined}
      <StakeAmount
        action={action}
        value={stakeAmount}
        setValue={setStakeAmount}
        loadedValue={loadedAtoms.has(action)}
      />
      {/* <BoostCard
        baseApy={apy.toNumber()}
        extraApy={10}
        visible={action === "stake"}
      ></BoostCard> */}
      <StakeButton action={action} amount={stakeAmount} fracDigits={3} />
      <StakingInfo
        amount={stakeAmount}
        action={action}
        tsTonPrice={currency.convert(Big(1), "TON", "tsTON")}
        available={available}
        staked={account.connected ? account.userStaked : Big(0)}
        lose={
          account.connected
            ? currency.getProjectedEarnings(
                clamp(0, stakeAmount, account.max[action]),
              ).yearly
            : Big(0)
        }
      />
      <Terms />
    </div>
  );
};
