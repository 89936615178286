import { animated, useSpring } from "@react-spring/web";
import { useEffect } from "preact/hooks";

import { Text } from "@/uikit";

import "./style.css";

import { useSetAtom } from "jotai";
import { RefObject } from "preact";
import { JSX } from "preact/jsx-runtime";

import { focusAtom } from "@/components/StakeAmount";

type TabsProps<T extends string> = {
  entries: T[];
  onChange: (key: T) => void;
  selected: string;
  isActive: boolean;
  wasActive: boolean;
  names: Record<T, string>;
  tabContainerRef?: RefObject<HTMLDivElement>;
};

export const Tabs = <T extends string>(
  {
    selected,
    entries,
    onChange,
    isActive,
    wasActive,
    names,
    tabContainerRef,
  }: TabsProps<T>,
): JSX.Element | null => {
  const setFocus = useSetAtom(focusAtom);

  const entryCount = entries.length;
  const currentIndex = entries.findIndex((x) => x === selected);

  const [props, api] = useSpring(() => ({
    from: {
      left: currentIndex,
      opacity: 1,
    },
  }));

  useEffect(() => {
    api.start({
      to: {
        opacity: +isActive,
      },
    });
  }, [isActive]);

  useEffect(() => {
    api.start({
      to: {
        left: currentIndex + 0.000001,
      },
      immediate: !wasActive,
    });
  }, [currentIndex, selected, wasActive]);

  const preventFocusLoss = (key: string) => (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    if (!["stake", "unstake"].includes(key)) {
      setFocus(true);
    }
  };

  const buttons = entries.map((key) => (
    <animated.button
      key={key}
      onClick={() => {
        onChange(key);
      }}
      onMouseDown={preventFocusLoss(key)}
    >
      <Text
        variant="caption"
        weight="semibold"
        data-earn={key === "earn" ? true : undefined}
      >
        {names[key]}
      </Text>
    </animated.button>
  ));

  return (
    <div
      class="tabs"
      style={{ "--entry-count": entryCount }}
      ref={tabContainerRef}
    >
      <div class="tabs-buttons">{buttons}</div>
      <animated.div
        class="tabs-slider"
        style={{
          left: props.left.to((x) =>
            x ? `calc(${x} * var(--tab-full-size))` : 0,
          ),
          opacity: props.opacity,
        }}
      >
        <animated.div
          style={{
            x: props.left.to((x) =>
              x ? `calc(${-x} * var(--tab-full-size))` : 0,
            ),
          }}
          className="tabs-buttons fake-buttons"
        >
          {buttons}
        </animated.div>
      </animated.div>
    </div>
  );
};
