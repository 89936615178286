import { FarmData, useFarmingAccount } from "@/hooks/useFarmingAccount";

import "./style.css";

import Big from "big.js";
import { useAtom, useSetAtom } from "jotai";
import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import { FarmingCard, FarmingInput, FarmingToken, Terms } from "@/components";
import { Link, Text, TextButton } from "@/uikit";

import { FarmingModal } from "@/components/farming/Modal";
import { useAccount } from "@/hooks/useAccount";
import { pageAtom, subPageAtom } from "@/state/page";
import { useModal } from "@/uikit/Modal";
import { formatTime } from "@/utils/numbers";
import { urlSafe } from "@/utils/urls";

import { FarmingFarmTable } from "./table";

type FarmingFarmProps = {
  farm: FarmData;
  balance: Big;
  stake: (amount: Big) => Promise<boolean>;
  index: number;
};

export const FarmingFarm: FunctionalComponent<FarmingFarmProps> = (
  { farm, balance, stake, index },
) => {
  const [value, setValue] = useState(Big(0));
  const [isDefault, setIsDefault] = useState(true);
  const canStake = value.lte(balance) && value.gt(0);
  const farmingAccount = useFarmingAccount();
  const account = useAccount();
  const modalControls = useModal();
  const setPage = useSetAtom(pageAtom);
  const [currentSubPage, setCurrentSubpage] = useAtom(subPageAtom);

  const thisPage = urlSafe(farm.token.name);

  const isOpen = thisPage === currentSubPage;

  const setIsOpen = (val: boolean) => {
    if (val) {
      setCurrentSubpage(thisPage);
    } else {
      setCurrentSubpage("");
    }
  };

  const buyHost = farm.token.name === "tsTON" ? "Stake" : "ston.fi";

  const buyURL = farm.token.name === "tsTON" ? undefined : farm.buyURL;
  const buyClick = () => farm.token.name === "tsTON" && setPage("stake");

  useEffect(() => {
    if (!isDefault) {
      return;
    }

    setValue(balance);
  }, [isDefault, balance.toString()]);

  return (
    <FarmingToken
      farm={farm}
      key={farm.farmAddress + "-farm"}
      isFirst={!index}
      section="farms"
      setIsOpen={setIsOpen}
      isOpen={isOpen}
    >
      <FarmingCard>
        <Text variant="caption" muted className="farm-description">
          {farm.description}
        </Text>
      </FarmingCard>
      {balance.gt(0.0001) ? (
        <>
          <FarmingInput
            farm={farm}
            value={value}
            setValue={(value) => {
              setValue(value);
              setIsDefault(false);
            }}
            balance={balance}
            isOpen={isOpen}
          />
          <FarmingFarmTable farm={farm} balance={balance} />
        </>
      ) : (
        <FarmingCard className="please-buy-card">
          <Text className="please-buy" variant="h4" weight="semibold">
            You don't have any {farm.token.name} for staking yet. Please, visit{" "}
            <Link href={buyURL} onClick={buyClick}>
              {buyHost}
            </Link>{" "}
            to get it.
          </Text>
        </FarmingCard>
      )}

      {farmingAccount.connected ? (
        balance.gt(0.0001) ? (
          <TextButton
            onClick={() => {
              if (!canStake) {
                return;
              }
              modalControls.addLayer(({ controls }) => (
                <FarmingModal
                  action="deposit"
                  amount={value}
                  stake={() => {
                    setIsOpen(false);
                    return stake(value);
                  }}
                  controls={controls}
                  lockup={formatTime(
                    farm.farmingData?.min_stake_time.toNumber() || 0,
                    true,
                  )}
                  token={farm.token.name}
                />
              ));
            }}
            disabled={!true}
          >
            Deposit {farm.token.name}
          </TextButton>
        ) : (
          <Link
            className="button please-buy-button"
            href={buyURL}
            onClick={buyClick}
          >
            <Text weight="semibold" variant="h4">
              Visit {buyHost}
            </Text>
          </Link>
        )
      ) : (
        <TextButton onClick={() => !account.connected && account.connect()}>
          Connect account to deposit {farm.token.name}
        </TextButton>
      )}
      <Terms />
    </FarmingToken>
  );
};
