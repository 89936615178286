import { ButtonProps } from "../Button";
import { FunctionalComponent } from "preact";

import { Button, Text } from "@/uikit";

import "./style.css";

export const TextButton: FunctionalComponent<ButtonProps> = (
  { children, ...rest },
) => {
  return (
    <Button {...rest}>
      <Text variant="h4" weight="semibold">
        {children}
      </Text>
    </Button>
  );
};
