import { Inputs, useEffect } from "preact/hooks";

type Listener<Ev extends unknown, T extends unknown> = (
  this: T,
  ev: Ev,
  options?: boolean | AddEventListenerOptions,
) => void;

type ManageListener<
  EK extends string,
  Ev extends unknown,
  Opt extends object,
> = (key: EK, listener: (event: Ev) => void, options?: boolean | Opt) => void;

type Listenable<
  EK extends string,
  Ev extends unknown,
  Opt1 extends object,
  Opt2 extends object,
> = {
  addEventListener: ManageListener<EK, Ev, Opt1>;
  removeEventListener: ManageListener<EK, Ev, Opt2>;
};

type ListenableInferred<T, K extends string> = T extends Listenable<
  K,
  infer Ev,
  infer Opt1,
  infer Opt2
>
  ? {
      listenable: T;
      event: Ev;
      addOptions: Opt1;
      removeOptions: Opt2;
    }
  : never;

export const useEventListener = <
  EK extends string,
  T extends Listenable<EK, unknown, object, object> | undefined,
>(
  target: T,
  eventKey: EK,
  listener: Listener<ListenableInferred<T, EK>["event"], T>,
  deps?: Inputs,
) => {
  useEffect(() => {
    if (!target) {
      return;
    }
    target.addEventListener(eventKey, listener);
    return () => target.removeEventListener(eventKey, listener);
  }, deps);
};
