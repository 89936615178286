import clsx from "clsx";
import { useSetAtom } from "jotai";
import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { Button } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { pageAtom } from "@/state/page";
import { ButtonProps } from "@/uikit/Button";

import "./style.css";

type ConnectButtonProps = {} & ButtonProps;

export const ConnectButton: FunctionalComponent<ConnectButtonProps> = (
  { className, ...rest },
) => {
  const account = useAccount();
  const contents = account.connected ? account.shortAddress : "Connect wallet";
  const setPage = useSetAtom(pageAtom);

  const buttonHandler = () => {
    if (account.connected) {
      setPage("account");
    } else {
      account.connect();
    }
  };

  return (
    <Button
      id="connect-wallet"
      className={clsx("connect-button", className)}
      {...rest}
      onClick={buttonHandler}
    >
      <Icon iconKey="tonlogo" /> {contents}
    </Button>
  );
};
