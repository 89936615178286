import { BoostColor, SectionBar } from "@/uikit/SectionBar";

import "./style.css";

import { Offer } from "..";
import { OfferTitles } from "../Offer";
import Big from "big.js";
import { FunctionalComponent } from "preact";

import { Card, PointList, Text } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import { formatAmount, formatNumber } from "@/utils/numbers";

export type EarnOfferSection = {
  color: BoostColor;
  value: number;
  description: (value: number) => string;
};

export type EarnOfferData = {
  name: string;
  icon: string;
  sections: EarnOfferSection[];
};

type EarnOfferProps = EarnOfferData & {
  i: number;
  isOpen: boolean;
  isActive: boolean;
  onClick: () => void;
  amount: Big;
};

export const EarnOffer: FunctionalComponent<EarnOfferProps> = (
  { name, icon, sections, isActive, isOpen, onClick, amount, i },
) => {
  const apySum = sections.reduce(
    (prev, section) => prev.add(section.value),
    Big(0),
  );
  const currency = useCurrency();
  const earnings = currency.convert(
    apySum.div(100).add(1).mul(amount).sub(amount),
    "TON",
    "tsTON",
  );
  const apyFormatted = formatNumber(apySum, false, 2, false) + "%";

  const titles: [string, string, string] = ["Protocol", "You earn", "APY"];

  return (
    <>
      {i ? undefined : <OfferTitles titles={titles} />}
      <Card className="earn-offer" active={isActive} onClick={onClick}>
        <Offer
          icon={icon}
          offerTitle={name}
          isOpen={isOpen}
          setIsOpen={() => {}}
          titles={titles}
          valuesLoaded={[true, true]}
          values={[
            formatAmount({
              amount: earnings,
              currency: "TON",
              fracDigits: 2,
              stripZeros: false,
            }),
            apyFormatted,
          ]}
        >
          <div className="offer-boost-card gap-vertical">
            <Text>
              <Text embed weight="semibold">
                {apyFormatted}
              </Text>{" "}
              total APY
            </Text>
            <SectionBar
              sections={sections.map((section) => ({
                color: section.color,
                size: section.value,
                active: true,
              }))}
            />
            <PointList
              points={sections.map((section) => ({
                color: section.color,
                active: true,
                contents: section.description(section.value),
              }))}
            />
          </div>
        </Offer>
      </Card>
    </>
  );
};
