import { useAtomValue } from "jotai";
import { useRef } from "preact/hooks";

import { scrolledAtom } from "@/state/layout";
import { setHeaderColor } from "@/utils/tma";

import { useIsMobile } from "./useIsMobile";

export const useThemeColor = () => {
  const scrollState = useAtomValue(scrolledAtom);
  const isMobile = useIsMobile();
  const themeRef = useRef<HTMLMetaElement | null>(null);

  const setThemeRef = () => {
    if (!themeRef.current) {
      themeRef.current = document.querySelector('meta[name="theme-color"]');
    }
  };

  const setThemeColor = (color: string) => {
    setThemeRef();
    setHeaderColor(color);
    themeRef.current!.content = color;
  };

  if (isMobile) {
    if (scrollState) {
      setThemeColor("#252C33");
    } else {
      setThemeColor("#1B2025");
    }
  }
};
