import clsx from "clsx";
import { ComponentProps, FunctionalComponent } from "preact";

import { ClassNamed } from "@/types/classname";

import "./style.css";

type CardProps = ClassNamed<ComponentProps<"div"> & { active?: boolean }>;

export const Card: FunctionalComponent<CardProps> = (
  { children, className, active, ...rest },
) => {
  return (
    <div className={clsx("card", className)} data-active={active} {...rest}>
      {children}
    </div>
  );
};
