const identSet = new Set("abcdefghijklmnopqrstuvwxyz0123456789_-");

export const urlSafe = (name: string): string => {
  return [...name.toLowerCase()]
    .map((x) => {
      if (x.trim()) {
        return x;
      }
      return "-";
    })
    .filter((x) => identSet.has(x))
    .join("")
    .replaceAll(/^[^a-z0-9]|[^a-z0-9]$/g, "");
};
