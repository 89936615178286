import { FunctionalComponent } from "preact";

import { Text, TextButton } from "@/uikit";

import { ModalControls } from "@/uikit/Modal";

import "./style.css";

type BalanceModalProps = {
  controls: ModalControls;
};

export const BalanceModal: FunctionalComponent<BalanceModalProps> = (
  { controls },
) => {
  return (
    <div class="balance-modal-modal">
      <div className="balance-modal-description gap-vertical">
        <Text variant="h4" weight="semibold">
          Why is 'Available to stake' amount less than my actual balance?
        </Text>
        <Text muted>
          To stake TON, you need to pay a small fee to the network.
          <br />
          <br />
          We leave 1.1 TON untouched on your wallet so that you have enough to
          pay for stake.
          <br />
          <br />
          Keep in mind that you also will need to pay a similar fee on unstake.
        </Text>
      </div>
      <div className="balance-modal-buttons">
        <TextButton onClick={controls.close}>Got it</TextButton>
      </div>
    </div>
  );
};
