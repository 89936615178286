import Big from "big.js";

import { Currency } from "@/state/currency";

import { type TonClient } from "./client";

type Prices = Record<Currency, Big>;

export const getPrices = async (tonClient: TonClient): Promise<Prices> => {
  const rates = await tonClient.tonstakers.getRates();

  // @ts-ignore
  const newPrices: Prices = { USD: Big(1) };

  newPrices.TON = Big(rates.TONUSD);
  newPrices.tsTON = newPrices.TON.mul(rates.tsTONTON);
  newPrices.tsTONProjected = newPrices.TON.mul(rates.tsTONTONProjected);

  return newPrices;
};
