import { Link, Text } from "@/uikit";

import "./style.css";

import { FunctionalComponent } from "preact";

type TermsProps = {};

export const Terms: FunctionalComponent<TermsProps> = ({}) => {
  return (
    <Text variant="caption" muted className="legal">
      By&nbsp;using the&nbsp;app, you confirm compliance with our&nbsp;
      <Link href="https://tonstakers.com/terms-of-service/">
        Terms&nbsp;of&nbsp;Service
      </Link>
      .
    </Text>
  );
};
