import clsx from "clsx";
import { FunctionalComponent } from "preact";

import { Header, TabPanel } from "@/components";

import { ClassNamed } from "@/types/classname";

import "./style.css";

import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "preact/hooks";

import { useEventListener } from "@/hooks";

import { addressAtom } from "@/state/api";
import { pageAtom, subPageAtom } from "@/state/page";
import { sendEncryptedWalletEvent } from "@/utils/analytics";
import {
  getCurrentPage,
  getCurrentSubPage,
  getPath,
} from "@/utils/getCurrentPage";

type BaseProps = ClassNamed<{}>;

export const Base: FunctionalComponent<BaseProps> = (
  { children, className },
) => {
  const [currentPage, setCurrentPage] = useAtom(pageAtom);
  const [currentSubPage, setCurrentSubPage] = useAtom(subPageAtom);
  const walletAddress = useAtomValue(addressAtom);

  useEventListener(window, "popstate", () => {
    setCurrentPage(getCurrentPage());
    setCurrentSubPage(getCurrentSubPage());
  });

  useEffect(() => {
    if (
      currentPage !== getCurrentPage() ||
      currentSubPage !== getCurrentSubPage()
    ) {
      window.history.replaceState({}, "", getPath(currentPage, currentSubPage));
    }

    if (currentPage.includes("stake")) {
      sendEncryptedWalletEvent(`app_page_loaded_${currentPage}`, walletAddress);
    }
  }, [currentPage, currentSubPage]);

  return (
    <>
      <Header />
      <main className={clsx("page", "gap-vertical", className)}>
        <TabPanel />
        {children}
      </main>
    </>
  );
};
