export const SwapIcon = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-swap"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1429 21.8571C14.853 21.8571 15.4286 21.2815 15.4286 20.5714C15.4286 19.8613 14.853 19.2857 14.1429 19.2857H8.24688L21.4806 6.05197C21.9827 5.54987 21.9827 4.7358 21.4806 4.23369C20.9785 3.73159 20.1644 3.73159 19.6623 4.23369L6.42861 17.4674V11.5714C6.42861 10.8613 5.85297 10.2857 5.14289 10.2857C4.43281 10.2857 3.85718 10.8613 3.85718 11.5714V20.5714C3.85718 21.2815 4.43281 21.8571 5.14289 21.8571H14.1429ZM21.8572 14.1428C21.1471 14.1428 20.5715 14.7185 20.5715 15.4285C20.5715 16.1386 21.1471 16.7143 21.8572 16.7143H27.7532L14.5195 29.948C14.0174 30.4501 14.0174 31.2642 14.5195 31.7663C15.0216 32.2684 15.8356 32.2684 16.3377 31.7663L29.5715 18.5325V24.4285C29.5715 25.1386 30.1471 25.7143 30.8572 25.7143C31.5673 25.7143 32.1429 25.1386 32.1429 24.4285V15.4285C32.1429 14.7185 31.5673 14.1428 30.8572 14.1428H21.8572Z"
      fill="currentColor"
    />
  </svg>
);
