import { SpringRef } from "@react-spring/web";
import { atom, useAtom } from "jotai";
import { FunctionalComponent } from "preact";

import { Onboarding, StakeModal } from "@/components";
import { useIsMobile } from "@/hooks";

import { Action } from "@/types/action";

import "./style.css";

import { OnboardingType } from "@/components/Onboarding";
import { ResultModal } from "@/components/StakeModal/resultModal";

import { ModalCard } from "./card";

export type ModalControls = {
  close: () => void;
  resize: () => void;
};

type Controlling = FunctionalComponent<{ controls: ModalControls }>;

export type ModalLayer = {
  contents: Controlling;
  isOpen: boolean;
  layerID: number;
};

const modalLayersAtom = atom<ModalLayer[]>([]);
const layerCountAtom = atom<number>(0);

export const useModal = () => {
  const [modalLayers, setModalLayers] = useAtom(modalLayersAtom);
  const [layerCount, setLayerCount] = useAtom(layerCountAtom);
  const isMobile = useIsMobile();

  const unmountLayer = (layerID: number) => {
    setModalLayers((layers) =>
      layers.filter((layer) => layer.layerID !== layerID),
    );
  };

  const closeLayer = (layerID: number, spring: SpringRef<{ y: number }>) => {
    setModalLayers(
      modalLayers.map((layer) => {
        if (layer.layerID !== layerID) {
          return layer;
        }

        return {
          ...layer,
          isOpen: false,
        };
      }),
    );

    const unmount = () => unmountLayer(layerID);

    if (isMobile) {
      spring.start({ to: { y: 0 }, onRest: unmount });
    } else {
      setTimeout(unmount, 300);
    }
  };

  const addLayer = (layerComponent: Controlling) => {
    setModalLayers((layers) => [
      ...layers,
      {
        contents: layerComponent,
        isOpen: true,
        layerID: layerCount,
      },
    ]);
    setLayerCount((x) => x + 1);
  };

  const openStakeModal = (amount: Big, action: Action) => {
    addLayer((props) => (
      <StakeModal action={action} amount={amount} {...props} />
    ));
  };

  const openOnboarding = (type: OnboardingType = "general") => {
    addLayer((props) => <Onboarding {...props} type={type} />);
  };

  const openResultModal = (
    amount: Big,
    action: Action,
    success: boolean,
    bestRate: boolean,
  ) => {
    addLayer((props) => (
      <ResultModal
        action={action}
        amount={amount}
        success={success}
        bestRate={bestRate}
        {...props}
      />
    ));
  };

  return {
    closeLayer,
    addLayer,
    modalLayers,
    openStakeModal,
    openResultModal,
    openOnboarding,
  };
};

export const Modal: FunctionalComponent = () => {
  const { modalLayers } = useModal();

  return (
    <div class="modal-container">
      {modalLayers.map((layer, i) => (
        <ModalCard layer={layer} isLast={i + 1 === modalLayers.length} />
      ))}
    </div>
  );
};
