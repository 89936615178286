import { ComponentChildren } from "preact";

import { Link } from "@/uikit";

export type FAQPointData = {
  title: string;
  children: ComponentChildren;
};

export type FAQType = "general" | "earn" | "farm";

const generalFaqPoints: FAQPointData[] = [
  {
    title: "What is Liquid Staking?",
    children: [
      <>
        Liquid Staking allows you to&nbsp;earn yield on&nbsp;your TON coins
        while keeping your funds under your full control.
        <br />
        <Link href="https://faq.tonstakers.com/article/75-what-is-liquid-staking">
          Learn more
        </Link>
      </>,
    ],
  },
  {
    title: "How does Tonstakers work?",
    children: [
      <>
        Tonstakers participate in&nbsp;the blockchain staking process,
        generating yield from processing transactions and minting new blocks.
        Rewards are distributed every 18&nbsp;hours.
        <br />
        <Link href="https://faq.tonstakers.com/article/87-how-does-tonstakers-work">
          Learn more
        </Link>
      </>,
    ],
  },
  {
    title: "How to use Tonstakers?",
    children: [
      <>
        Please read the article and watch the educational video on&nbsp;how
        to&nbsp;enjoy Tonstakers rewards.
        <br />
        <Link href="https://telegra.ph/Liquid-staking-with-Tonstakers-08-24">
          Link to&nbsp;article
        </Link>
      </>,
    ],
  },
  {
    title: "Is Tonstakers secure?",
    children: [
      <>
        Tonstakers use an&nbsp;open-source smart contract that has successfully
        passed internal audits and has been successfully{" "}
        <Link href="https://skynet.certik.com/projects/tonstakers">
          audited by&nbsp;Certik
        </Link>
        , Web3&rsquo;s leading smart contract auditor.
      </>,
    ],
  },
  {
    title: "What is tsTON?",
    children: [
      <>
        tsTON is&nbsp;a&nbsp;special crypto you get for staking, which you can
        use or&nbsp;swap in&nbsp;the TON network.
        <br />
        <Link href="https://faq.tonstakers.com/article/121-what-is-tston">
          Learn more
        </Link>
      </>,
    ],
  },
];

const earnFaqPoints: FAQPointData[] = [
  {
    title: "What is Earn with Tonstakers?",
    children: [
      <>
        Earn with Tonstakers is an exclusive joint initiative by TON Foundation
        and Tonstakers aimed for boosting yields for liquid staking users and
        amplifying ecosystem's liquidity.
      </>,
    ],
  },
  {
    title: "What is APY?",
    children: [
      <>
        APY (Annual Percentage Yield) means how much you will earn per year on
        your investment while reinvesting profits as frequently as possible
        (compounding profits).
      </>,
    ],
  },
  {
    title: "How to boost APY with Earn with Tonstakers?",
    children: [
      <>
        Stake TON with Tonstakers to start earning up to 4.5% APY in TON and
        receive tsTON immediately. Then provide your tsTON to EVAA, STON.fi or
        DeDust to earn up to 11% APY in USDT. Now you are earning up to 15% APY
        in the safest TON assets in total. Learn about EVAA, STON.fi, and DeDust
        next.
      </>,
    ],
  },
  {
    title: "How to boost staking APY with EVAA",
    children: [
      <>
        1. Make sure you have tsTON in your wallet.
        <br />
        2. Go to tsTON Supply page on EVAA app.
        <br />
        3. Click Supply button.
        <br />
        4. Input how much tsTON you want to supply and click Supply button.
        <br />
        Now you'll be earning tsTON from lending your tsTON (base APY) and USDT
        from Earn with Tonstakers (boosted APY).
        <br />
      </>,
    ],
  },
  {
    title: "How to boost staking APY with STON.fi",
    children: [
      <>
        1. Make sure you have tsTON and USDT in your wallet.
        <br />
        2. Go to tsTON/USDT Liquidity Pool page on STON.fi app.
        <br />
        3. Click Add liquidity button and input how much tsTON and USDT you want
        to supply.
        <br />
        4. Now you have LP tokens for tsTON/USDT pair. Provide them to farm to
        start earning boosted rewards.
        <br />
        Now you'll be earning a share of tsTON/USDT pair trading fees (base APY)
        and USDT from Earn with Tonstakers farm (boosted APY).
        <br />
      </>,
    ],
  },
  {
    title: "How to boost staking APY with DeDust",
    children: [
      <>
        1. Make sure you have tsTON and USDT in your wallet.
        <br />
        2. Go to tsTON/USDT Liquidity Pool page on DeDust app.
        <br />
        3. Click Add liquidity button and input how much tsTON and USDT you want
        to supply.
        <br />
        4. Now you have LP tokens for tsTON/USDT pair. Provide them to farm to
        start earning boosted rewards.
        <br />
        Now you'll be earning a share of tsTON/USDT pair trading fees (base APY)
        and USDT from Earn with Tonstakers farm (boosted APY).
      </>,
    ],
  },
  {
    title: "How to claim Earn from Tonstakers rewards?",
    children: [
      <>
        How to claim Earn from Tonstakers rewards?
        <br />
        Staking rewards are automatically accumulated in your tsTON. You don't
        have to claim them manually and will receive all TON earned with staking
        to your wallet after unstaking.
        <br />
        EVAA supply rewards and STON.fi and DeDust trading fees are
        automatically accumulated in pools, you don’t need to claim them.
        <br />
        However, rewards from farming tsTON/USDT LP has to be claimed from the
        farm on the liquidity pools page.
      </>,
    ],
  },
];

const farmingFaqPoints: FAQPointData[] = [
  {
    title: "What is Tonstakers Token Staking?",
    children: [
      <>
        Token Staking on Tonstakers gives users the access to popular TON
        projects which reward their holders through the smartest and most
        comfortable DeFi mechanism — staking.
      </>,
    ],
  },
  {
    title: "What is APR?",
    children: [
      <>
        Project APR on Token Staking depends on the total reward pool the
        project provided for stakers and the total amount of tokens staked
        (TVL). The amount of rewards distributed to users is calculated based on
        these values and is expressed as an annual percentage rate — APR.
      </>,
    ],
  },
  {
    title: "How are Token Staking rewards distributed?",
    children: [
      <>
        Token stakers receive rewards from projects in tokens specified in the
        token staking terms. Rewards accumulate in staking smart contract and
        users have to claim them manually.
      </>,
    ],
  },
  {
    title: "How Token Staking is different from farming?",
    children: [
      <>
        Token Staking is an interface and a transparent mechanism for projects
        to distribute rewards which has no downsides that are usually present in
        farming. Users stake single token instead of token pairs, they don't
        need to manage LP, and are protected from impermanent loss.
      </>,
    ],
  },
  {
    title: "What is Tonstakers?",
    children: [
      <>
        Tonstakers is the #1 TON Liquid Staking Protocol in terms of total value
        locked, security, user count, and community members count.
      </>,
    ],
  },
];

export const faqPoints: Record<FAQType, FAQPointData[]> = {
  earn: earnFaqPoints,
  general: generalFaqPoints,
  farm: farmingFaqPoints,
};
