import clsx from "clsx";
import { useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";

import { ConnectButton, Logo } from "@/components";

import { scrolledAtom } from "@/state/layout";

import "./style.css";

export const Header: FunctionalComponent = () => {
  const scrolled = useAtomValue(scrolledAtom);

  return (
    <header class={clsx("header", scrolled && "scrolled")}>
      <Logo />
      <ConnectButton />
    </header>
  );
};
