import { ClassNamed } from "@/types/classname";

import "./style.css";

import clsx from "clsx";
import { ComponentProps, FunctionalComponent } from "preact";

type FarmingCardProps = ClassNamed<ComponentProps<"div">>;

export const FarmingCard: FunctionalComponent<FarmingCardProps> = (
  { className, ...rest },
) => {
  return <div class={clsx("farming-card", className)} {...rest} />;
};
