import { FarmingNFT, FarmingToken } from "@/components";

import "./style.css";

import { countRewards } from "../NFT";
import Big from "big.js";
import { useAtom } from "jotai";
import { FunctionalComponent } from "preact";

import { useTokenPrices } from "@/api/useBlockchainData";
import { useCurrency } from "@/hooks/useCurrency";
import { FarmingWalletData } from "@/hooks/useFarmingAccount";
import { subPageAtom } from "@/state/page";
import { fromDecimals } from "@/utils/numbers";
import { urlSafe } from "@/utils/urls";

type FarmingWalletProps = {
  wallet: FarmingWalletData;
  index: number;
};

export const FarmingWallet: FunctionalComponent<FarmingWalletProps> = (
  { wallet, index },
) => {
  const tokenContract = wallet.farmData.token.contract;

  const [currentSubPage, setCurrentSubpage] = useAtom(subPageAtom);

  const tokenPrices = useTokenPrices(tokenContract);
  const currency = useCurrency();
  const nfts = wallet.userStake;

  const tvl = tokenPrices.convert(
    fromDecimals(wallet.stakedTokens, wallet.farmData.token.decimals),
    tokenContract,
    currency.base,
  );

  const apy = tokenPrices.convert(
    nfts.reduce((acc, nft) => acc.add(countRewards(wallet, nft)), Big(0)),
    wallet.farmData.rewardTokens[0].contract,
    currency.base,
  );

  const thisPage = `collection-${urlSafe(wallet.farmData.token.name)}`;
  const isOpen = thisPage === currentSubPage;

  const setIsOpen = (val: boolean) => {
    if (val) {
      setCurrentSubpage(thisPage);
    } else {
      setCurrentSubpage("");
    }
  };

  return (
    <FarmingToken
      farm={wallet.farmData}
      tvl={tvl}
      apy={apy}
      isFirst={!index}
      section="portfolio"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {wallet.userStake.map((nft, i) => (
        <FarmingNFT
          wallet={wallet}
          index={i}
          key={nft.address}
          showIndex={wallet.userStake.length > 1}
          setIsOpen={setIsOpen}
        />
      ))}
    </FarmingToken>
  );
};
