import { FunctionalComponent } from "preact";
import { memo } from "preact/compat";

import { ChevronIcon } from "./iconData/chevron";
import { ClockIcon } from "./iconData/clock";
import { CloseIcon } from "./iconData/close";
import { CloseAltIcon } from "./iconData/closeAlt";
import { ConfettiIcon } from "./iconData/confetti";
import { CookieIcon } from "./iconData/cookie";
import { GlobeIcon } from "./iconData/globe";
import { HelpIcon } from "./iconData/help";
import { LightningIcon } from "./iconData/ligtning";
import { NFTIcon } from "./iconData/nft";
import { PerfIcon } from "./iconData/perf";
import { ShieldIcon } from "./iconData/shield";
import { SwapIcon } from "./iconData/swap";
import { TickIcon } from "./iconData/tick";
import { TonLogo } from "./iconData/ton";

export type IconKey =
  | "chevron"
  | "clock"
  | "close"
  | "closealt"
  | "confetti"
  | "cookie"
  | "globe"
  | "help"
  | "lightning"
  | "nft"
  | "perf"
  | "shield"
  | "swap"
  | "tick"
  | "tonlogo";

const IconSpriteBase: FunctionalComponent = () => {
  return (
    <svg className="icon-sprite">
      <defs>
        {ChevronIcon}
        {ClockIcon}
        {CloseAltIcon}
        {CloseIcon}
        {ConfettiIcon}
        {CookieIcon}
        {GlobeIcon}
        {HelpIcon}
        {LightningIcon}
        {NFTIcon}
        {PerfIcon}
        {ShieldIcon}
        {SwapIcon}
        {TickIcon}
        {TonLogo}
      </defs>
    </svg>
  );
};

export const IconSprite = memo(IconSpriteBase);
