import { FarmingCard, FarmingModal } from "@/components/farming";

import "./style.css";

import Big from "big.js";
import { FunctionalComponent } from "preact";
import { useState } from "preact/hooks";

import { Table, TextButton } from "@/uikit";

import { NFTData } from "@/api/farming/farming";
import {
  FarmingWalletData,
  useFarmingAccount,
} from "@/hooks/useFarmingAccount";
import { useInterval } from "@/hooks/useInterval";
import { useModal } from "@/uikit/Modal";
import {
  clamp,
  formatAmountTitled,
  formatTime,
  fromDecimals,
  fromNano,
} from "@/utils/numbers";

type FarmingNFTProps = {
  wallet: FarmingWalletData;
  index: number;
  showIndex: boolean;
  setIsOpen: (value: boolean) => void;
};

export const countRewards = (wallet: FarmingWalletData, nft: NFTData): Big => {
  const farmingData = wallet.farmData.farmingData;
  const decimals = wallet.farmData.token.decimals;

  if (!farmingData) {
    return Big(0);
  }

  const currentRewards = farmingData.accrued_per_unit_nanorewards;

  const accumulatedRewards = currentRewards
    .sub(nft.claimed_per_unit_nanorewards)
    .mul(nft.staked_tokens)
    .div(10 ** decimals);

  return fromNano(accumulatedRewards);
};

const getRemainingLockup = (minStakeTime: number, stakeStart: number) => {
  return formatTime(Math.max(0, stakeStart + minStakeTime - Date.now() / 1000));
};

export const FarmingNFT: FunctionalComponent<FarmingNFTProps> = (
  { wallet, index, showIndex, setIsOpen },
) => {
  const farmingAccount = useFarmingAccount();
  const nft = wallet.userStake[index];
  const accumulatedRewards = countRewards(wallet, nft);
  const modalControls = useModal();

  const minStakeTime = wallet.farmData.farmingData?.min_stake_time;
  const stakeDuration = Date.now() / 1000 - nft.stake_date;

  const isLocked =
    minStakeTime !== undefined ? minStakeTime.gt(stakeDuration) : true;

  const [unlocksIn, setUnlocksIn] = useState(() =>
    getRemainingLockup(minStakeTime?.toNumber() || 0, nft.stake_date),
  );

  useInterval(
    () => {
      setUnlocksIn(
        getRemainingLockup(minStakeTime?.toNumber() || 0, nft.stake_date),
      );
    },
    1000,
    [minStakeTime?.toString(), nft.stake_date, wallet],
  );

  const canInteract = !!(farmingAccount.connected && !isLocked);

  return (
    <FarmingCard
      className="farming-nft vertical-gap"
      data-visible={nft.staked_tokens.gt(0)}
    >
      <Table
        rows={[
          showIndex
            ? {
                left: { text: `Position #${index + 1}`, muted: true },
                right: { text: "" },
              }
            : undefined,
          {
            left: { text: "Deposit" },
            right: {
              ...formatAmountTitled({
                amount: fromDecimals(
                  nft.staked_tokens,
                  wallet.farmData.token.decimals,
                ),
                fracDigits: 4,
                stripZeros: true,
                currency: wallet.farmData.token.name,
                tonSuffix: false,
              }),
              muted: true,
            },
          },
          {
            left: { text: "Accumulated rewards" },
            right: {
              ...formatAmountTitled({
                amount: clamp(0, accumulatedRewards),
                fracDigits: 6,
                stripZeros: false,
                currency: wallet.farmData.rewardTokens[0].name,
                tonSuffix: false,
                bottomEdge: Big("0.000001"),
              }),
              muted: true,
            },
          },
          {
            left: { text: "Unlocks in" },
            right: {
              text: minStakeTime ? unlocksIn : "Loading...",
            },
            visible: isLocked,
          },
        ]}
      />
      <div class="farming-nft-buttons">
        <TextButton
          onClick={() => {
            if (!farmingAccount.connected) {
              return;
            }
            modalControls.addLayer((props) => (
              <FarmingModal
                {...props}
                action="claimRewards"
                wallet={wallet}
                nft={nft}
              />
            ));
            setTimeout(() => setIsOpen(false), 500);
          }}
          disabled={!farmingAccount.connected}
        >
          Claim rewards
        </TextButton>
        <TextButton
          onClick={() => {
            if (!canInteract) {
              return;
            }
            modalControls.addLayer((props) => (
              <FarmingModal
                {...props}
                action="withdraw"
                wallet={wallet}
                nft={nft}
              />
            ));
            setIsOpen(false);
          }}
          disabled={!canInteract}
          buttonType="outline"
        >
          Withdraw
        </TextButton>
      </div>
    </FarmingCard>
  );
};
