import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import { CurrencyFormat } from "@/components";
import { Heading, Table, Text, TextButton } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { ModalControls } from "@/uikit/Modal";
import { formatAmount, fromNano } from "@/utils/numbers";

import "./style.css";

import { countRewards } from "../NFT";
import Big from "big.js";

import { NFTData } from "@/api/farming/farming";
import { ResultModal } from "@/components/StakeModal/resultModal";
import {
  FarmingWalletData,
  useFarmingWalletUpdate,
} from "@/hooks/useFarmingAccount";

type FarmingUnstakeACtion = "withdraw" | "claimRewards";
type FarmingAction = "deposit" | FarmingUnstakeACtion;

type FarmingModalProps = { controls: ModalControls } & (
  | {
      wallet: FarmingWalletData;
      nft: NFTData;
      action: FarmingUnstakeACtion;
    }
  | {
      action: "deposit";
      lockup: string;
      amount: Big;
      stake: () => Promise<boolean>;
      token: string;
    }
);

type ConfirmActionProps = {
  action: FarmingAction;
  wallet?: FarmingWalletData;
  nft?: NFTData;
  setStatus: (value: boolean) => void;
  updateBalances: () => void;
  stake?: () => Promise<boolean>;
};

export const confirmAction = async (
  { action, stake, wallet, nft, setStatus, updateBalances }: ConfirmActionProps,
) => {
  // sendEncryptedWalletEvent(`app_${action}`, account.rawAddress);

  let request: Promise<boolean>;

  if (action === "deposit" && stake) {
    request = stake();
  } else {
    if (!wallet || !nft) {
      setStatus(false);
      return;
    }

    if (action === "withdraw") {
      request = wallet.withdraw(nft.address, wallet.farmData.farmAddress);
    } else {
      request = wallet.claimRewards(nft.address, wallet.farmData.farmAddress);
    }
  }

  const status = await request;

  // sendEncryptedWalletEvent(
  //   `app_${action}_${status ? "success" : "error"}`,
  //   account.rawAddress,
  // );
  setStatus(!!status);
  updateBalances();
};

const actionTexts: Record<FarmingAction, string> = {
  claimRewards: "claim",
  deposit: "stake",
  withdraw: "withdraw",
};

export const FarmingModal: FunctionalComponent<FarmingModalProps> = (props) => {
  const account = useAccount();
  const { updateWallets } = useFarmingWalletUpdate();
  const [confirmed, setConfirmed] = useState(false);
  const [success, setSuccess] = useState(false);

  let token: string = "";
  let amount: Big = props.action === "deposit" ? props.amount : Big(0);

  if (props.action !== "deposit") {
    token =
      props.action === "claimRewards"
        ? props.wallet.farmData.rewardTokens[0].name
        : props.wallet.farmData.token.name;

    amount =
      props.action === "withdraw"
        ? fromNano(props.nft.staked_tokens)
        : countRewards(props.wallet, props.nft);
  } else {
    token = props.token;
  }

  const actionText = actionTexts[props.action];

  useEffect(() => {
    props.controls.resize();
  }, [confirmed, success]);

  if (!account.connected) {
    return null;
  }

  const confirmActionInner = async () => {
    let request: Promise<void>;

    if (props.action === "deposit") {
      request = confirmAction({
        action: props.action,
        setStatus: setSuccess,
        updateBalances: updateWallets,
        stake: props.stake,
      });
    } else {
      request = confirmAction({
        action: props.action,
        wallet: props.wallet,
        nft: props.nft,
        setStatus: setSuccess,
        updateBalances: updateWallets,
      });
    }
    await request;
    setConfirmed(true);
  };

  if (confirmed) {
    return (
      <ResultModal
        action={props.action}
        amount={amount}
        bestRate={false}
        controls={props.controls}
        success={success}
        token={token}
      />
    );
  }

  return (
    <>
      <div className="stake-modal-header gap-vertical">
        <Text variant="h4" weight="semibold">
          {props.action === "deposit"
            ? "You can't withdraw your tokens for the next"
            : `You ${actionText}`}
        </Text>
        <Heading level={1}>
          {props.action === "deposit" ? (
            props.lockup.toUpperCase()
          ) : (
            <CurrencyFormat tonSuffix={false} amount={amount} base={token}>
              {formatAmount({
                amount,
                fracDigits: props.action === "claimRewards" ? 6 : 4,
                bottomEdge:
                  props.action === "claimRewards" ? "0.000001" : "0.0001",
                isCurrency: false,
              })}
            </CurrencyFormat>
          )}
        </Heading>
      </div>
      <div className="stake-modal-content gap-vertical">
        <Text variant="h4" weight="semibold">
          Details
        </Text>
        <Table
          rows={[
            {
              left: { text: "Blockchain fee" },
              right: {
                text: "0.05-1 TON",
              },
            },
            props.action === "deposit"
              ? {
                  left: { text: "Amount" },
                  right: {
                    text: formatAmount({
                      amount,
                      currency: props.token,
                      fracDigits: 4,
                      stripZeros: true,
                      tonSuffix: false,
                    }),
                    muted: true,
                  },
                }
              : undefined,
          ]}
        />
      </div>
      <div className="stake-modal-confirm farming-modal">
        <TextButton onClick={confirmActionInner} disabled={!account.connected}>
          Confirm to {actionText}
        </TextButton>
      </div>
    </>
  );
};
