import { BoostColor } from "@/uikit/SectionBar";

import "./style.css";

import { ComponentChildren, FunctionalComponent } from "preact";

import { Text } from "@/uikit";

type Point = {
  color: BoostColor;
  contents: ComponentChildren;
  active: boolean;
};

type PointListProps = {
  points: Point[];
};

export const PointList: FunctionalComponent<PointListProps> = ({ points }) => {
  return (
    <div className="point-list gap-vertical">
      {points.map((point, i) => (
        <div className="point-list-point" key={i}>
          <div
            className="point-list-marker"
            data-color={point.color}
            data-active={point.active}
          />
          <Text variant="caption">{point.contents}</Text>
        </div>
      ))}
    </div>
  );
};
