export const ERROR_MESSAGES = {
  NOT_ENOUGH: {
    stake: "You don't have enough TON",
    unstake: "You don’t have enough tsTON",
  },
  MIN_STAKE: {
    stake: "You need at least 1 TON to stake.",
    unstake: "You need more than 0 TON to unstake.",
  },
  MIN_DEPOSIT: (token: string) => `You need more than 0 ${token} to deposit`,
  NOT_ENOUGH_DEPOSIT: (token: string) => `You don't have enough ${token}`,
} as const;
