export const TickIcon = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-tick"
  >
    <path
      d="M20 34.5004L29.1366 43.6363L45.7729 25"
      stroke="currentColor"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
