import Big from "big.js";
import { useAtom, useAtomValue } from "jotai";

import { currentApyAtom } from "@/state/api";
import {
  historicalApyAtom,
  prevRoundRoiAtom,
  roundRoiAtom,
} from "@/state/api/blockchainData";
import { baseCurrencyAtom, Currency, pricesAtom } from "@/state/currency";
import { getEarnings } from "@/utils/earnings";

export const useCurrency = () => {
  const [base, setBase] = useAtom(baseCurrencyAtom);
  const prices = useAtomValue(pricesAtom);

  const currentApy = useAtomValue(currentApyAtom).div(100);
  const historicalApy = useAtomValue(historicalApyAtom).div(100);
  const roundRoi = useAtomValue(roundRoiAtom);
  const prevRoundRoi = useAtomValue(prevRoundRoiAtom);

  const convert = (
    value: Big,
    destination: Currency,
    source: Currency = base,
  ): Big => {
    if (prices[destination].eq(0)) {
      return Big(0);
    }

    const rate = prices[source].div(prices[destination]);
    return rate.mul(value);
  };

  const getProjectedEarnings = (amount: Big) =>
    getEarnings(historicalApy, amount, roundRoi);
  const getPreviousEarnings = (amount: Big) =>
    getEarnings(currentApy, amount, prevRoundRoi);

  return {
    convert,
    base,
    setBase,
    getProjectedEarnings,
    getPreviousEarnings,
    currentApy,
    historicalApy,
    projectedApy: historicalApy,
    fromTON: (value: Big) => convert(value, base, "TON"),
    toTON: (value: Big) => convert(value, "TON", base),
    loaded: !!(prices.TON && currentApy.gt(0) && historicalApy.gt(0)),
  };
};
