import { FunctionalComponent } from "preact";

import "./style.css";

type CaptionProps = {
  label?: string;
};

export const Caption: FunctionalComponent<CaptionProps> = (
  { children, label },
) => {
  return (
    <p class="caption" aria-label={label}>
      {children}
    </p>
  );
};
