import { atom, useAtomValue, useSetAtom } from "jotai";

import { useEventListener } from "./useEventListener";

const mobileQuery = "(max-width: 768px)";
const getIsMobile = () => window.matchMedia(mobileQuery).matches;
const isMobileAtom = atom(getIsMobile());

export const useMobileState = () => {
  const setIsMobile = useSetAtom(isMobileAtom);

  const mediaQueryList = window.matchMedia(mobileQuery);
  const handleResize = () => setIsMobile(getIsMobile());

  useEventListener(mediaQueryList, "change", handleResize);
};

export const useIsMobile = () => {
  return useAtomValue(isMobileAtom);
};
