import { useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";

import { Text } from "@/uikit";

import { baseCurrencyAtom } from "@/state/currency";
import { ClassNamed } from "@/types/classname";
import { CurrencySymbol, formatAmount, getSymbols } from "@/utils/numbers";

import "./style.css";

type CurrencyFormatProps = ClassNamed<{
  tonSuffix?: boolean;
  base?: string;
  amount: Big;
}>;

export type InlineFormat = {
  "data-currency-prefix"?: string;
  "data-currency-suffix"?: string;
};

type SymbolData = {
  base: string;
  symbols: CurrencySymbol;
};

const getSymbolData = (base: string): SymbolData => {
  return {
    base,
    symbols: getSymbols(base),
  };
};

const useSymbols = (base?: string): SymbolData => {
  const currentBase = useAtomValue(baseCurrencyAtom);

  return getSymbolData(base || currentBase);
};

export const getInlineFormat = (props: CurrencyFormatProps): InlineFormat => {
  const { symbols } = getSymbolData(props.base || "TON");
  return {
    "data-currency-prefix": symbols.prefix,
    "data-currency-suffix": `${symbols.suffix || ""}${
      props.tonSuffix ? symbols.tonSuffix || "" : ""
    }`,
  };
};

export const useInlineFormat = (props: CurrencyFormatProps): InlineFormat => {
  const { symbols } = useSymbols(props.base);
  return {
    "data-currency-prefix": symbols.prefix,
    "data-currency-suffix": `${symbols.suffix || ""}${
      props.tonSuffix ? symbols.tonSuffix || "" : ""
    }`,
  };
};

export const CurrencyFormat: FunctionalComponent<CurrencyFormatProps> = (
  { children, amount, tonSuffix = true, base, className },
) => {
  const symbolData = useSymbols(base);
  const currency = symbolData.base;
  const { symbols } = symbolData;

  return (
    <Text
      embed
      title={formatAmount({
        amount,
        isPrecise: true,
        currency,
        tonSuffix,
      })}
      className={className}
    >
      {symbols.prefix && (
        <Text embed className="currency-format">
          {symbols.prefix}
        </Text>
      )}
      {children}
      {symbols.suffix && (
        <Text embed className="currency-format">
          {symbols.suffix}
        </Text>
      )}
      {tonSuffix ? (
        <Text embed className="currency-format">
          {symbols.tonSuffix}
        </Text>
      ) : null}
    </Text>
  );
};
