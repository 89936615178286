import { FunctionalComponent } from "preact";
import { useRef, useState } from "preact/hooks";

import { useInterval } from "@/hooks/useInterval";

import "./style.css";

type LoaderProps = {
  checkValue: unknown;
};

export const Loader: FunctionalComponent<LoaderProps> = (
  { checkValue, children },
) => {
  const loaderRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState(2 * 16);

  useInterval(
    () => {
      if (!loaderRef.current) {
        return;
      }

      const loader = loaderRef.current;

      if (checkValue) {
        loader.style.removeProperty("max-width");
        return;
      }

      let width = 0;

      for (const child of loader.children) {
        width += child.scrollWidth;
      }

      setMaxWidth(width);
    },
    100,
    [children, checkValue],
  );

  return (
    <div
      class="loader"
      data-active={!checkValue}
      ref={loaderRef}
      style={{ maxWidth: checkValue ? undefined : maxWidth }}
    >
      <span>{children}</span>
    </div>
  );
};
