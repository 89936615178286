import "./style.css";

import { FunctionalComponent } from "preact";

type SpinnerProps = {};

export const Spinner: FunctionalComponent<SpinnerProps> = ({}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    className="spinner"
  >
    <path className="spinner-arc" d="M25,4 A21,21 0 0 1 25,46" />
  </svg>
);
