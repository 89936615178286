import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { Text } from "@/uikit";

import { formatAmount } from "@/utils/numbers";

import "./style.css";

import { atom, useAtom } from "jotai";
import { useEffect } from "preact/hooks";

import { RoundCountdown } from "@/components";

type RewardProps = {
  next: Big;
};

const rewardShownAtom = atom(false);

export const Reward: FunctionalComponent<RewardProps> = ({ next }) => {
  const [shown, setShown] = useAtom(rewardShownAtom);

  useEffect(() => {
    setShown(true);
  }, []);

  const formatReward = (amount: Big) => {
    return formatAmount({
      amount,
      currency: "TON",
      fracDigits: 7,
      stripZeros: true,
      prefix: amount ? "+" : "",
    });
  };

  return (
    <div className="reward-card gap-horizontal plaque" data-shown={shown}>
      <Icon iconKey="confetti" />
      <div className="reward-card-description gap-vertical">
        <Text variant="body" weight="semibold">
          {formatReward(next)} will be credited in <RoundCountdown />.
        </Text>
      </div>
    </div>
  );
};
