import { Wallet } from "@tonconnect/ui";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "preact/hooks";

import { walletAtom } from "@/state/api/account";
import { getAddress } from "@/utils/address";
import { sendEncryptedWalletEvent } from "@/utils/analytics";

import { useBalanceUpdate } from "./useAccount";
import { useFarmingWalletUpdate } from "./useFarmingAccount";
import { useInterval } from "./useInterval";
import { tonClientAtom } from "./useTonstakers";

export const useWallet = () => {
  const tonClient = useAtomValue(tonClientAtom);
  const { updateBalances } = useBalanceUpdate();
  const { updateWallets } = useFarmingWalletUpdate();
  const setWalletRaw = useSetAtom(walletAtom);

  const setWallet = (wallet: Wallet | null) => {
    if (wallet && tonClient) {
      updateBalances();
      updateWallets();
    }

    setWalletRaw(wallet);

    sendEncryptedWalletEvent(
      "app_wallet_connected",
      wallet ? getAddress(wallet) : null,
      {
        wallet_type: wallet?.device.appName || null,
      },
    );
  };

  useInterval(
    () => {
      if (!tonClient) {
        return;
      }
      updateBalances();
      updateWallets();
    },
    1000,
    [tonClient],
  );

  useEffect(() => {
    if (!tonClient) {
      return;
    }

    const tonConnect = tonClient.tonConnectUI;
    const wallet = tonConnect.wallet;

    if (wallet) {
      setWallet(wallet);
    }

    tonConnect.onStatusChange(setWallet);
  }, [tonClient]);
};
