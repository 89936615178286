import { atom } from "jotai";

import {
  getCurrentPage,
  getCurrentSubPage,
  Page,
} from "@/utils/getCurrentPage";

export const pageAtom = atom<Page>(getCurrentPage());
export const subPageAtom = atom<string>(getCurrentSubPage());

const onboardingKey = "ts-onboarding-shown";
const onboardingEarnKey = "ts-earn-onboarding-shown";

const onboardedAtomBase = atom(!!localStorage.getItem(onboardingKey));

export const onboardedAtom = atom(
  (get) => get(onboardedAtomBase),
  (_, set, value: boolean) => {
    set(onboardedAtomBase, value);

    if (!value) {
      localStorage.removeItem(onboardingKey);
    } else {
      localStorage.setItem(onboardingKey, "true");
    }
  },
);

const onboardedEarnAtomBase = atom(!!localStorage.getItem(onboardingEarnKey));

export const onboardedEarnAtom = atom(
  (get) => get(onboardedEarnAtomBase),
  (_, set, value: boolean) => {
    set(onboardedEarnAtomBase, value);

    if (!value) {
      localStorage.removeItem(onboardingEarnKey);
    } else {
      localStorage.setItem(onboardingEarnKey, "true");
    }
  },
);
