export const pages = ["stake", "unstake", "account", "tokens", "earn"] as const;
const pageSet = new Set(pages);

export type Page = (typeof pages)[number];

const getPage = (path: string): Page => {
  if (pageSet.has(path as Page)) {
    return path as Page;
  }

  return "stake";
};

const getPathParts = (): string[] => {
  return location.pathname.slice(1).split("/");
};

export const getCurrentPage = (): Page => {
  return getPage(getPathParts()[0]);
};

export const getCurrentSubPage = (): string => {
  return getPathParts()[1] || "";
};

export const getPath = (page: string, subpage: string) => {
  if (page === "stake") {
    return "/";
  }

  if (pageSet.has(page as Page)) {
    if (subpage) {
      return `/${page}/${subpage}`;
    }

    return "/" + page;
  }

  return "/";
};
