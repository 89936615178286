import { InlineFormat } from "../CurrencyFormat";
import Big from "big.js";
import clsx from "clsx";
import { FunctionalComponent, RefObject } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";

import { ClassNamed } from "@/types/classname";

import "./style.css";

import { useEventListener } from "@/hooks";

type CurrencyInputProps = InlineFormat &
  ClassNamed<{
    inputRef: RefObject<HTMLSpanElement>;
    value: Big;
    setValue: (newValue: Big) => void;
  }>;

export const CurrencyInput: FunctionalComponent<CurrencyInputProps> = (
  { className, value, inputRef, setValue, ...rest },
) => {
  const [displayValue, setDisplayValue] = useState(value.toFixed(2, 1));
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!focused) {
      setDisplayValue(value.eq(0) ? "" : value.toFixed(2, 1));
    }
  }, [value, focused]);

  useEventListener(
    document,
    "visibilitychange",
    () => {
      const isVisible = document.visibilityState
        ? document.visibilityState === "visible"
        : !document.hidden;

      if (!isVisible) {
        inputRef.current && inputRef.current.blur();
      }
    },
    [],
  );

  const handleChange = useCallback(
    (e: Event) => {
      const target = e.target as HTMLInputElement;
      const newValue = target.value;
      const cursorPosition = target.selectionStart || 0;
      const regex = /^(?:[1-9]\d{0,15}|0)?(\.\d{0,9})?$/;

      if (regex.test(newValue)) {
        setDisplayValue(newValue);

        if (newValue !== "" && newValue !== "-" && newValue !== ".") {
          const bigValue = Big(newValue);
          if (!bigValue.eq(value)) {
            setValue(bigValue);
          }
        } else if (newValue === "") {
          setValue(Big(0));
        }
      } else {
        e.preventDefault();
        target.value = displayValue;
        target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      }
    },
    [value, setValue, displayValue],
  );

  const handleBlur = useCallback(() => {
    setFocused(false);
    if (displayValue === "" || displayValue === "." || displayValue === "-") {
      setValue(Big(0));
      setDisplayValue("");
    } else {
      const bigValue = Big(displayValue);
      setValue(bigValue);
      setDisplayValue(bigValue.toString());
    }
  }, [displayValue, setValue]);

  const handleFocus = useCallback(() => {
    setFocused(true);
    if (value.eq(0)) {
      setDisplayValue("");
    }
  }, [value]);

  return (
    <span className={clsx("currency-input", className)}>
      <input
        type="text"
        value={focused ? displayValue : value.eq(0) ? "0" : displayValue}
        onInput={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        ref={inputRef}
        inputMode="numeric"
        style={{
          width: `${Math.max(displayValue.length, 1)}ch`,
          minWidth: "1ch",
        }}
        {...rest}
      />
      <span className="display">{displayValue || "0"}</span>
    </span>
  );
};

export default CurrencyInput;
