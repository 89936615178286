import Big from "big.js";
import { useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";

import { InfoCard } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import {
  stakersCountAtom,
  totalValueLockedAtom,
} from "@/state/api/blockchainData";
import { formatAmount, formatFloatLocalized } from "@/utils/numbers";

import "./style.css";

import { ProtocolDetailsPoint } from "./point";

export const ProtocolDetails: FunctionalComponent = () => {
  const totalValueLocked = useAtomValue(totalValueLockedAtom);
  const stakersCount = useAtomValue(stakersCountAtom);
  const currency = useCurrency();

  const TVL = currency.convert(totalValueLocked, currency.base, "TON");

  return (
    <InfoCard title="Protocol details">
      <div className="protocol-details">
        <ProtocolDetailsPoint
          title="#1"
          subtitle="Staking on TON"
          value={1}
          tooltip="#1 staking on TON"
        />
        <ProtocolDetailsPoint
          title={`${formatAmount({
            amount: TVL.gt(0) ? TVL : undefined,
            placeholder: Big(250_000_000),
            fracDigits: 1,
            currency: currency.base,
            tonSuffix: false,
          })}`}
          subtitle="TVL"
          value={TVL.toNumber()}
          tooltip={formatAmount({
            amount: TVL,
            isPrecise: true,
            currency: "USD",
            tonSuffix: false,
          })}
        />
        <ProtocolDetailsPoint
          title={formatFloatLocalized(
            stakersCount.gt(0) ? stakersCount : Big(99999),
          )}
          subtitle="Stakers now"
          value={stakersCount.toNumber()}
          tooltip="The staker count"
        />
      </div>
    </InfoCard>
  );
};
