import { captureException } from "@sentry/react";
import Big from "big.js";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "preact/hooks";

import { useCurrency } from "@/hooks/useCurrency";
import { FarmData, farmsDataAtom } from "@/hooks/useFarmingAccount";
import { useInterval } from "@/hooks/useInterval";
import { tonClientAtom } from "@/hooks/useTonstakers";
import {
  currentApyAtom,
  cycleEndAtom,
  historicalApyAtom,
  prevRoundRoiAtom,
  roundRoiAtom,
  stakersCountAtom,
  totalValueLockedAtom,
} from "@/state/api/blockchainData";
import { BaseCurrency, pricesAtom } from "@/state/currency";
import { fromDecimals, fromNano } from "@/utils/numbers";

import { getPrices } from "./fetchData";

export const useBlockchainData = () => {
  const setTVL = useSetAtom(totalValueLockedAtom);
  const setStakersCount = useSetAtom(stakersCountAtom);
  const setCurrentApy = useSetAtom(currentApyAtom);
  const setHistoricalApy = useSetAtom(historicalApyAtom);
  const tonClient = useAtomValue(tonClientAtom);
  const setPrevRoundRoi = useSetAtom(prevRoundRoiAtom);
  const roundRoi = useAtomValue(roundRoiAtom);
  const setCycleEnd = useSetAtom(cycleEndAtom);
  const [farms, setFarms] = useAtom(farmsDataAtom);
  const { getPrice } = useTokenPrices();

  const updateData = async () => {
    if (!tonClient) {
      return;
    }

    const sdk = tonClient.tonstakers;

    setTVL(fromNano(await sdk.getTvl()));
    setStakersCount(Big(await sdk.getStakersCount()));
    const currentApy = Big(await sdk.getCurrentApy());

    setCurrentApy(currentApy);

    const historicalApys = await sdk.getHistoricalApy();

    const last90days = historicalApys.slice(-91, -1).map((x) => Big(x.apy));

    const apySum = last90days.reduce((a, b) => a.add(b), Big(0));
    const dayCount = last90days.length || 1;

    const avgHistoricalApy = apySum.div(dayCount);

    setHistoricalApy(avgHistoricalApy);

    const lastDayApy = last90days.slice(-1)[0];

    const [, cycleEnd] = await sdk.getRoundTimestamps();

    setCycleEnd(cycleEnd);

    if (roundRoi && currentApy.gt(0)) {
      let prevRoundRoi: Big;

      try {
        prevRoundRoi = roundRoi.sub(1).div(currentApy).mul(lastDayApy).add(1);
      } catch {
        return;
      }

      setPrevRoundRoi(prevRoundRoi);
    }

    const newFarms: FarmData[] = [...farms];

    for (const farm of newFarms) {
      farm.farmingData = await tonClient.farmingSDK.fetchFarmingData(
        farm.farmAddress,
      );

      if (!farm.farmingData) {
        continue;
      }

      const tvl = fromDecimals(
        await tonClient.farmingSDK.getTvl(farm.farmAddress),
        farm.token.decimals,
      );
      const depositPrice = await getPrice(farm.token.contract);
      const usdTvl = tvl.mul(depositPrice);

      farm.tvl = {
        USD: usdTvl,
        native: tvl,
      };

      const rewardPrice = await getPrice(farm.rewardTokens[0].contract);

      const totalDailyRewards = fromNano(
        farm.farmingData.nanorewards_per_24h,
      ).div(10 ** farm.rewardTokens[0].decimals);

      const totalDailyRewardsUSD = rewardPrice.mul(totalDailyRewards);

      const dpy = tvl.gt(0) ? totalDailyRewardsUSD.div(usdTvl) : Big("0");
      farm.apy = dpy.mul(365);

      const logo = await tonClient.farmingSDK.fetchJettonIcon(
        farm.token.contract,
      );

      if (logo) {
        farm.logo = logo;
      }
    }
    setFarms(newFarms);
  };

  useEffect(() => {
    updateData();
  }, [tonClient, roundRoi, tonClient?.farmingSDK.isTestnet]);

  useInterval(updateData, 30000);
};

export const useRates = (force?: boolean) => {
  const setPrices = useSetAtom(pricesAtom);
  const tonClient = useAtomValue(tonClientAtom);
  const setRoundRoi = useSetAtom(roundRoiAtom);

  useEffect(() => {
    if (!tonClient) {
      return;
    }

    (async () => {
      const prices = await getPrices(tonClient);

      setRoundRoi(prices.tsTONProjected.div(prices.tsTON));
      setPrices(prices);
    })();
  }, [force, tonClient]);
};

type TokenPriceInternal = {
  usdPrice: number;
  fetchedAt: number;
};

type TokenUSDPrice = Big;

const PRICE_REFRESH_RATE = 600000;

const tokenPricesAtom = atom<Record<string, TokenPriceInternal>>({});

const isFresh = (createdAt: number, ttl: number) => {
  return createdAt + ttl > Date.now();
};

export const useTokenPrices = (wallet?: string) => {
  const [prices, setPrices] = useAtom(tokenPricesAtom);
  const currency = useCurrency();

  const fetchData = async (wallet: string): Promise<TokenPriceInternal> => {
    const data = await (
      await fetch(`https://api.ston.fi/v1/assets/${wallet}`)
    ).json();

    return {
      usdPrice: data.asset.dex_price_usd,
      fetchedAt: Date.now(),
    };
  };

  const getPrice = async (
    wallet: string,
    force?: boolean,
  ): Promise<TokenUSDPrice> => {
    let currentPriceData = prices[wallet];

    if (
      !currentPriceData ||
      force ||
      !isFresh(currentPriceData.fetchedAt, PRICE_REFRESH_RATE)
    ) {
      currentPriceData = await fetchData(wallet);

      if (!currentPriceData.usdPrice) {
        captureException("token USD price fetch error", {});
        return Big(0);
      }

      setPrices((old) => {
        return {
          ...old,
          [wallet]: currentPriceData,
        };
      });
    }

    return Big(currentPriceData.usdPrice);
  };

  const convert = (
    amount: Big,
    wallet: string,
    destination: BaseCurrency,
  ): Big => {
    if (!prices[wallet]) {
      return Big(0);
    }

    return currency.convert(
      amount.mul(prices[wallet].usdPrice),
      destination,
      "USD",
    );
  };

  useEffect(() => {
    if (wallet) {
      getPrice(wallet);
    }
  }, [wallet, prices, getPrice]);

  useInterval(
    () => {
      if (wallet) {
        getPrice(wallet);
      }
    },
    1000,
    [wallet, prices, getPrice],
  );

  return {
    convert,
    getPrice,
    prices,
  };
};
