import { Icon } from "@/icons";

import "./style.css";

import { FunctionalComponent } from "preact";

import { Link } from "@/uikit";

type HelpButtonProps = {
  onClick: () => void;
};

export const HelpButton: FunctionalComponent<HelpButtonProps> = (
  { children, onClick },
) => {
  return (
    <Link className="help-button" onClick={onClick}>
      {children}
      <Icon iconKey="help" />
    </Link>
  );
};
