import "./style.css";

import { FunctionalComponent } from "preact";

type MaxButtonProps = {
  setMax: () => void;
};

export const MaxButton: FunctionalComponent<MaxButtonProps> = ({ setMax }) => {
  return (
    <button className="max-button" onClick={() => setMax()}>
      Use MAX
    </button>
  );
};
