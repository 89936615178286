import { useAtom } from "jotai";
import { FunctionalComponent } from "preact";

import { Select, Tabs } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import { BaseCurrency } from "@/state/currency";
import { pageAtom } from "@/state/page";
import { SelectItem } from "@/uikit/Select";

import "./style.css";

import { useEffect, useState } from "preact/hooks";

import { tabNames } from "@/uikit/Tabs/names";
import { Page } from "@/utils/getCurrentPage";

type TabPanelProps = {};

const Currencies: SelectItem<BaseCurrency>[] = [
  {
    key: "USD",
    name: "US Dollar",
  },
  { key: "TON", name: "Toncoin" },
];

const getTabs = (): Page[] => {
  return ["stake", "tokens"];
};

export const TabPanel: FunctionalComponent<TabPanelProps> = ({}) => {
  const [page, setPage] = useAtom(pageAtom);
  const [tab, setTabRaw] = useState(page);
  const [lastPage, setLastPage] = useState(page);
  const currency = useCurrency();
  const tabs = getTabs();

  const setTab = (newTab: Page) => {
    if (tabs.includes(newTab)) {
      setTabRaw(newTab);
    }
    if (newTab === "unstake") {
      setTabRaw("stake");
    }

    if (page === newTab) {
      return;
    }

    setPage((prev) => {
      setLastPage(prev);
      return newTab;
    });
  };

  useEffect(() => {
    setTab(page);
  }, [page]);

  return (
    <div class="tab-panel">
      <Tabs
        entries={tabs}
        onChange={setTab}
        selected={["stake", "unstake"].includes(page) ? "stake" : tab}
        isActive={page === "unstake" || tabs.includes(page)}
        wasActive={tabs.includes(lastPage)}
        names={tabNames}
      />
      <Select
        items={Currencies}
        selected={currency.base}
        onSelect={currency.setBase}
      />
    </div>
  );
};
