import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";

import "./style.css";

type OverlayProps = {
  closeCallback: () => void;
  isOpen: boolean;
};

export const Overlay: FunctionalComponent<OverlayProps> = (
  { closeCallback, isOpen },
) => {
  useEffect(() => {
    const bodyStyles = document.documentElement.style;

    if (isOpen) {
      bodyStyles.overflow = "hidden";
    } else {
      bodyStyles.removeProperty("overflow");
    }
  }, [isOpen]);

  return (
    <div
      class="overlay"
      data-open={isOpen}
      onClick={(e) => (e.target === e.currentTarget ? closeCallback() : null)}
    />
  );
};
