import { useAtom, useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import { EarnAmount, FAQ } from "@/components";
import { Caption, Heading, Link, Text, TextButton } from "@/uikit";

import { earnAmountAtom } from "@/components/EarnAmount";
import { EarnOffer, EarnOfferSection } from "@/components/EarnOffer";
import { useAccount } from "@/hooks/useAccount";
import { historicalApyAtom } from "@/state/api/blockchainData";
import { onboardedEarnAtom } from "@/state/page";
import { useModal } from "@/uikit/Modal";

import { getOfferDescription, offers } from "./offers";

export const Earn: FunctionalComponent<{ page: any }> = () => {
  const [earnOnboarded, setEarnOnboarded] = useAtom(onboardedEarnAtom);

  const modalControls = useModal();
  const [currentCard, setCurrentCard] = useState(0);
  const account = useAccount();
  const value = useAtomValue(earnAmountAtom);
  const apy = useAtomValue(historicalApyAtom);

  useEffect(() => {
    if (!earnOnboarded) {
      modalControls.openOnboarding("earn");
      setEarnOnboarded(true);
    }
  }, [earnOnboarded]);

  const baseApySection: EarnOfferSection = {
    color: "purple",
    description: getOfferDescription("base APY"),
    value: apy.toNumber(),
  };

  return (
    <>
      <div className="calculator gap-vertical">
        <Heading level={1}>Choose DeFi to Boost Your Yields</Heading>
        <Caption>Re-invest your tsTON to maximise your rewards</Caption>
        <EarnAmount />
        {offers.map((offer, i) => {
          const isCurrent = i === currentCard;

          return (
            <EarnOffer
              i={i}
              isOpen={isCurrent}
              isActive={isCurrent}
              onClick={() => setCurrentCard((cur) => (cur === i ? -1 : i))}
              amount={value}
              icon={offer.icon}
              name={offer.name}
              sections={[baseApySection, ...offer.sections]}
              key={offer.name}
            />
          );
        })}
        {account.connected ? (
          currentCard >= 0 ? (
            <Link className="button" href={offers[currentCard].url}>
              <Text weight="semibold" variant="h4">
                Get Extra Yield with {offers[currentCard].name}
              </Text>
            </Link>
          ) : (
            <TextButton data-disabled={true}>
              Choose an offer to continue
            </TextButton>
          )
        ) : (
          <TextButton onClick={account.connect}>
            Connect account to get extra yield
          </TextButton>
        )}
      </div>
      <FAQ type="earn" />
    </>
  );
};
