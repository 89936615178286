import Big from "big.js";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type BaseCurrency = "TON" | "USD";
export type Currency = BaseCurrency | "tsTON" | "tsTONProjected";

export const baseCurrencyAtom = atomWithStorage<BaseCurrency>(
  "pref-base-currency",
  "USD",
);

type Prices = Record<Currency, Big>;

export const pricesAtom = atom<Prices>({
  TON: Big(0),
  tsTON: Big(0),
  USD: Big(1),
  tsTONProjected: Big(0),
});
