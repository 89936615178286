import clsx from "clsx";
import { ComponentProps, FunctionalComponent } from "preact";

import "./style.css";

export type ButtonProps = ComponentProps<"button"> & {
  className?: string;
  isLoading?: boolean;
  buttonType?: "primary" | "secondary" | "outline";
};

export const Button: FunctionalComponent<ButtonProps> = (
  { className, isLoading, buttonType = "primary", ...rest },
) => {
  return (
    <button
      data-loading={isLoading}
      data-type={buttonType}
      className={clsx("button", className)}
      {...rest}
    />
  );
};
