import { useAtomValue } from "jotai";
import { JSX } from "preact/jsx-runtime";

import { pageAtom } from "@/state/page";
import { type Page } from "@/utils/getCurrentPage";

import { Account } from "./Account";
import { Base } from "./Base";
import { Earn } from "./Earn";
import { Farming } from "./Farming";
import { Stake } from "./Stake";

type PageFunction<T extends Page, R extends JSX.Element | null> = (
  props: {
    page: T;
  },
) => R;

const pages: { [K in Page]: PageFunction<K, JSX.Element | null> } = {
  stake: Stake,
  unstake: Stake,
  account: Account,
  earn: Earn,
  tokens: Farming,
};

const usePageComponent = <T extends Page>(): [
  T,
  PageFunction<T, JSX.Element | null>,
] => {
  const pageKey: T = useAtomValue(pageAtom) as T;

  return [pageKey, pages[pageKey]];
};

export const CurrentPage = () => {
  const [pageKey, Page] = usePageComponent();

  return (
    <Base>
      <Page page={pageKey} />
    </Base>
  );
};
