import Big from "big.js";
import { atom, useAtom } from "jotai";
import { FunctionalComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import { InputCard } from "@/components";
import { Link, Tabs } from "@/uikit";

import { useInlineFormat } from "@/components/CurrencyFormat";
import { useAccount } from "@/hooks/useAccount";
import { useCurrency } from "@/hooks/useCurrency";
import { pageAtom } from "@/state/page";
import { Action } from "@/types/action";
import { tabNames } from "@/uikit/Tabs/names";

type StakeAmountProps = {
  action: Action;
  value: Big;
  setValue: (newValue: Big) => void;
  loadedValue: boolean;
};

export const focusAtom = atom<boolean>(true);
export const inputMinPrecisionAtom = atom<number>(0);

export const StakeAmount: FunctionalComponent<StakeAmountProps> = (
  { action, value, setValue, loadedValue },
) => {
  const account = useAccount();
  const focusControls = useAtom(focusAtom);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);

  const [page, setPage] = useAtom(pageAtom);

  const tabsRef = useRef<HTMLDivElement>(null);

  const updateSize = () => {
    const tabs = tabsRef.current;

    if (!tabs) {
      return;
    }
    tabs.style.setProperty("--tab-size", `${tabs.clientWidth / 2}px`);
  };

  useEffect(() => {
    updateSize();
  }, []);

  const currency = useCurrency();
  const min = account.min[action];
  const max = account.isLoading ? Big(5000) : account.max[action];

  const valueProps = {
    value: currency.loaded ? currency.fromTON(value) : Big(1000),
    setValue: (value: Big) => {
      setValue(currency.toTON(value));
    },
  };

  const inputFormat = useInlineFormat({
    amount: valueProps.value,
    tonSuffix: true,
  });

  const sliderSetter = (value: Big) => {
    setValue(currency.toTON(currency.fromTON(value).round(2)));
  };

  useEffect(() => {
    const errorMessage = currency.loaded ? account.getError(action, value) : "";

    if (errorMessage) {
      setErrorMessage(errorMessage);
      setErrorVisible(true);
    } else {
      setErrorVisible(false);
    }
  }, [currency.loaded, action, value.toString()]);

  const sliderRange: [Big, Big] = [
    currency.toTON(currency.fromTON(min).round(2, 3)),
    currency.toTON(currency.fromTON(max).round(2, 0)),
  ];

  return (
    <InputCard
      action={action}
      error={errorMessage}
      errorSuffix={
        action === "unstake" ? (
          <Link onClick={() => setTimeout(() => setPage("stake"), 100)}>
            Stake
          </Link>
        ) : (
          <Link href="https://ton.org/buy-toncoin">Buy TON</Link>
        )
      }
      errorVisible={errorVisible && loadedValue}
      focusControls={focusControls}
      inputFormat={inputFormat}
      setMax={
        account.connected ? () => setValue(account.max[action]) : undefined
      }
      setSlider={sliderSetter}
      setValue={valueProps.setValue}
      sliderRange={sliderRange}
      sliderStep={
        account.connected
          ? Big(1).div(1000)
          : currency.convert(Big(1), "TON", currency.base)
      }
      sliderValue={value}
      showSlider={true}
      tabs={
        <Tabs
          entries={["stake", "unstake"]}
          onChange={setPage}
          selected={page}
          names={tabNames}
          tabContainerRef={tabsRef}
          isActive={true}
          wasActive={true}
        />
      }
      value={valueProps.value}
    />
  );
};
