import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { InfoCard, Text } from "@/uikit";

import { IconKey } from "@/icons/sprite";

import "./style.css";

type OverviewPoint = {
  icon: IconKey;
  text: string;
};

const points: OverviewPoint[] = [
  {
    icon: "clock",
    text: "Withdraw\nat any time",
  },
  {
    icon: "swap",
    text: "Rewards\nevery 18h",
  },
  {
    icon: "perf",
    text: "Min stake\nof 1 TON",
  },
];

export const Overview: FunctionalComponent = () => {
  return (
    <InfoCard title="Overview">
      <div className="overview-points">
        {points.map((point) => (
          <div className="overview-point">
            <Icon iconKey={point.icon} />
            <Text variant="subtitle">{point.text}</Text>
          </div>
        ))}
      </div>
    </InfoCard>
  );
};
