import { FunctionalComponent } from "preact";

import {
  Calculator,
  FAQ,
  Overview,
  ProtocolDetails,
  Security,
} from "@/components";

import "./style.css";

import { Action } from "@/types/action";

export const Stake: FunctionalComponent<{ page: Action }> = ({ page }) => {
  return (
    <>
      <Calculator action={page} />
      <Overview />
      <ProtocolDetails />
      <Security />
      <FAQ />
    </>
  );
};
