import { FunctionalComponent } from "preact";

import { TextButton } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { Action } from "@/types/action";
import { useModal } from "@/uikit/Modal";
import { capitalize } from "@/utils/capitalize";

import "./style.css";

import { confirmAction } from "../StakeModal";

type StakeButtonProps = {
  action: Action;
  amount: Big;
  fracDigits: number;
};

const getButtonText = (
  { action }: StakeButtonProps,
  connected: boolean,
): [string, string] => {
  let prefix = "";
  let suffix = "TON";

  if (connected) {
    prefix += capitalize(action);
  } else {
    prefix += "Connect wallet to " + action;
  }

  return [prefix, suffix];
};

export const StakeButton: FunctionalComponent<StakeButtonProps> = (
  { amount, action, fracDigits },
) => {
  const account = useAccount();
  const [prefix, _] = getButtonText(
    { amount, action, fracDigits },
    account.connected,
  );
  const errorMessage = account.getError(action, amount);
  const modal = useModal();

  const handler = () => {
    if (errorMessage) {
      return;
    }

    if (!account.connected) {
      account.connect();
      return;
    }

    if (action === "stake") {
      confirmAction({
        account,
        amount,
        action,
        bestRate: false,
        setStatus: (status: boolean) => {
          modal.openResultModal(amount, action, status, false);
        },
      });
      return;
    }

    modal.openStakeModal(amount, action);
  };

  return (
    <TextButton
      disabled={!!errorMessage}
      onClick={handler}
      title={errorMessage}
    >
      {prefix}
    </TextButton>
  );
};
