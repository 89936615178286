import Big from "big.js";

import "./style.css";

import { InlineFormat } from "../CurrencyFormat";
import { ComponentChildren, FunctionalComponent } from "preact";
import { useRef } from "preact/hooks";

import { AmountError, CurrencyInput, MaxButton } from "@/components";
import { Card, Heading, Slider, Text } from "@/uikit";

import { FocusControls, useFocus } from "@/hooks/useFocus";

type InputCardProps = {
  action: string;
  error: string;
  errorSuffix?: ComponentChildren;
  errorVisible: boolean;
  focusControls: FocusControls;
  inputFormat: InlineFormat;
  setMax?: () => void;
  setSlider?: (value: Big) => void;
  setValue: (value: Big) => void;
  showSlider: boolean;
  sliderRange?: [Big, Big];
  sliderStep?: Big;
  sliderValue?: Big;
  tabs?: ComponentChildren;
  value: Big;
} & (
  | {
      showSlider: false;
      sliderRange?: [Big, Big];
    }
  | {
      showSlider: boolean;
      sliderRange: [Big, Big];
    }
);

export const InputCard: FunctionalComponent<InputCardProps> = (
  {
    action,
    error,
    errorSuffix,
    errorVisible,
    focusControls,
    inputFormat,
    setMax,
    setSlider,
    setValue,
    showSlider,
    sliderRange,
    sliderStep,
    sliderValue,
    tabs,
    value,
  },
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { addLockFocus, releaseFocus, removeFocus } = useFocus(
    inputRef,
    focusControls,
  );

  return (
    <Card
      className="input-card"
      data-focused={focus}
      onMouseDown={addLockFocus}
      onMouseUp={releaseFocus}
      onBlur={removeFocus}
    >
      {tabs ? (
        <div className="input-card-tab-container">{tabs}</div>
      ) : undefined}
      <div className="input-card-padded">
        {setMax ? <MaxButton setMax={setMax} /> : undefined}
        <Text variant="body" className="input-card-action">
          You {action}
        </Text>
        <Heading level={1}>
          <div className="input-card-input">
            <label
              {...inputFormat}
              onMouseDown={(e) => {
                if (e.target !== e.currentTarget) {
                  return;
                }
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={addLockFocus}
            >
              <CurrencyInput
                inputRef={inputRef}
                value={value}
                setValue={setValue}
              />
            </label>
          </div>
        </Heading>
        <AmountError
          message={error || ""}
          visible={errorVisible}
          additional={errorSuffix}
        />
      </div>
      {showSlider ? (
        <Slider
          value={sliderValue || value}
          setValue={setSlider || setValue}
          min={sliderRange[0]}
          max={sliderRange[1]}
          step={sliderStep || sliderRange[0].sub(sliderRange[1]).div(1000)}
          onMouseDown={(e) => {
            releaseFocus();
            removeFocus();
            e.stopPropagation();
          }}
          onTouchStart={() => {
            releaseFocus();
            removeFocus();
          }}
        />
      ) : undefined}
    </Card>
  );
};
