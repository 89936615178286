import { FunctionalComponent } from "preact";

import { RowData, TableRow } from "@/uikit/TableRow";

import "./style.css";

type TableProps = {
  rows: (RowData | undefined)[];
};

export const Table: FunctionalComponent<TableProps> = ({ rows }) => {
  return (
    <div class="table gap-vertical">
      {rows.filter(Boolean).map((row, i) => (
        <TableRow key={i} row={row as RowData} />
      ))}
    </div>
  );
};
