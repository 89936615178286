import Big from "big.js";
import { FunctionalComponent } from "preact";

import { Table } from "@/uikit";

import { FarmData, useFarmingAccount } from "@/hooks/useFarmingAccount";
import { formatAmountTitled, formatTime } from "@/utils/numbers";

export type FarmingFarmTableProps = {
  farm: FarmData;
  balance: Big;
};

export const FarmingFarmTable: FunctionalComponent<FarmingFarmTableProps> = (
  { farm, balance },
) => {
  const farmingAccount = useFarmingAccount();

  return (
    <Table
      rows={[
        farmingAccount.connected
          ? {
              left: { text: "Your Balance" },
              right: {
                ...formatAmountTitled({
                  amount: balance,
                  currency: farm.token.name,
                  fracDigits: 4,
                  stripZeros: true,
                  tonSuffix: false,
                }),
                muted: true,
              },
            }
          : undefined,
        {
          left: {
            text: "Fee",
          },
          right: {
            text: "0.05 TON + transfer fee",
            muted: true,
          },
        },
        {
          left: { text: "Lock-up time" },
          right: {
            text: formatTime(farm.farmingData?.min_stake_time.toNumber() || 0),
            muted: true,
          },
          visible: !!farm.farmingData,
        },
      ]}
    />
  );
};
