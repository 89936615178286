import { FarmData } from "@/hooks/useFarmingAccount";

import "./style.css";

import Big from "big.js";
import { FunctionalComponent } from "preact";

import { Offer, OfferTitles } from "@/components/Offer";
import { useCurrency } from "@/hooks/useCurrency";
import { formatAmount } from "@/utils/numbers";

type FarmingTokenProps = {
  farm: FarmData;
  tvl?: Big;
  apy?: Big;
  isFirst?: boolean;
  section: string;
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
};

export const FarmingToken: FunctionalComponent<FarmingTokenProps> = (
  { farm, children, tvl, apy, isFirst, section, setIsOpen, isOpen },
) => {
  const isBalance = !!tvl;
  const currency = useCurrency();

  const farmTvl = farm.tvl
    ? currency.convert(farm.tvl.USD, currency.base, "USD")
    : Big(0);

  tvl = tvl || farmTvl;
  apy = apy || (farm.apy || Big(0.0)).mul(100);

  return (
    <>
      {isFirst ? (
        <OfferTitles
          titles={
            isBalance
              ? [
                  "Token Name",
                  "Balance, " + currency.base,
                  "Rewards, " + currency.base,
                ]
              : ["Token Name", "TVL, " + currency.base, "APR"]
          }
        />
      ) : undefined}
      <Offer
        className={`farming-token gap-vertical farming-${section}-token`}
        icon={farm.logo}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        offerTitle={farm.token.name}
        values={[
          formatAmount({
            amount: tvl,
            bottomEdge: isBalance ? 0.0001 : 1,
            fracDigits: isBalance ? 4 : 0,
            isCurrency: false,
            placeholder: Big(123456789),
            retainGroup: true,
            stripZeros: true,
            tonSuffix: false,
          }),
          !isBalance && apy.gt(999)
            ? "999+%"
            : formatAmount({
                amount: apy,
                bottomEdge: isBalance ? 0.0001 : 1,
                fracDigits: isBalance ? 4 : 0,
                isCurrency: false,
                retainGroup: true,
                stripZeros: isBalance,
                tonSuffix: false,
              }) + (isBalance ? "" : "%"),
        ]}
        valuesLoaded={[
          !!(isBalance ? tvl !== undefined : tvl?.gt(0)),
          !!(isBalance ? apy !== undefined : apy?.gt(0)),
        ]}
        deps={[farm, tvl, apy]}
      >
        {children}
      </Offer>
    </>
  );
};
