import Big from "big.js";
import { useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";

import { BalanceModal, ExchangeYield, HelpButton } from "@/components";
import { Table, Text } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { useCurrency } from "@/hooks/useCurrency";
import { tonClientAtom } from "@/hooks/useTonstakers";
import { Action } from "@/types/action";
import { useModal } from "@/uikit/Modal";
import { RowData } from "@/uikit/TableRow";
import {
  formatAmount,
  formatAmountTitled,
  formatNumber,
} from "@/utils/numbers";

type StakingInfoProps = {
  tsTonPrice?: Big;
  available?: Big;
  lose: Big;
  staked: Big;
  fee?: boolean;
  withdrawalFee?: boolean;
  amount: Big;
  action: Action;
};

export const StakingInfo: FunctionalComponent<StakingInfoProps> = (
  { action, amount, tsTonPrice, available, lose, staked, fee },
) => {
  const rows: RowData[] = [];
  const currency = useCurrency();
  const account = useAccount();
  const reserve = useAtomValue(tonClientAtom)?.FEE_RESERVE || 0;
  const modal = useModal();

  rows.push({
    left: {
      text: "You’ll get",
    },
    right: {
      text: <ExchangeYield amount={amount} />,
      muted: true,
      showLoader: !currency.loaded,
    },
    visible: action === "stake",
  });

  rows.push({
    left: {
      text: "Available for stake",
    },
    right: {
      text: (
        <HelpButton onClick={() => modal.addLayer(BalanceModal)}>
          <Text embed>
            {formatAmount({ amount: available, fracDigits: 2 })}{" "}
          </Text>
        </HelpButton>
      ),
      muted: true,
      showLoader: account.isLoading,
    },
    visible: !!available,
  });

  rows.push({
    left: {
      text: "Wallet balance",
    },
    right: {
      text: formatAmount({ amount: available?.add(reserve), fracDigits: 2 }),
      muted: true,
      showLoader: account.isLoading,
    },
    visible: !!(available && reserve),
  });

  if (fee) {
    rows.push({
      left: {
        text: "Fee",
      },
      right: {
        text: `0.05 TON + transfer fee`,
        muted: true,
      },
    });
  }

  const stakedFormatted = formatAmount({
    amount: staked,
    currency: "TON",
    fracDigits: 2,
  });

  const usdStakeFormatted = formatAmount({
    amount: currency.convert(staked, "USD", "TON"),
    currency: "USD",
    tonSuffix: false,
    fracDigits: 2,
  });

  let rightText = `${stakedFormatted} ≈ ${usdStakeFormatted}`;

  rows.push({
    left: {
      text: "Currently staked",
    },
    right: {
      text: rightText,
      muted: true,
      showLoader: !currency.loaded,
      title: formatAmount({ amount: staked, isPrecise: true }),
    },
    visible: action === "unstake" && staked.gt(0),
  });

  const historicalApy = currency.historicalApy.gt(0)
    ? currency.historicalApy
    : Big(365).div(10000);

  rows.push({
    left: {
      text: "Historical APY",
    },
    right: {
      text: formatNumber(historicalApy.mul(100), false, 2) + "%",
      className: "stake-apy",
      // onClick: modalState.openOnboarding,
      showLoader: !currency.loaded,
    },
  });

  if (tsTonPrice !== undefined) {
    rows.push({
      left: {
        text: "Exchange rate",
      },
      right: {
        text: `1 tsTON = ${formatNumber(tsTonPrice, false, 4)} TON`,
        muted: true,
        showLoader: !currency.loaded,
      },
    });
  }

  const loseAmountBase = currency.convert(lose, currency.base, "TON");

  const loseFormatted = formatAmountTitled({
    amount: loseAmountBase,
    fracDigits: 3,
    currency: currency.base,
  });

  rows.push({
    left: { text: "Potential loss next year" },
    right: {
      ...loseFormatted,
      weight: "semibold",
      className: "error",
      showLoader: !currency.projectedApy,
    },
    visible: lose?.gt(0.01) && action === "unstake",
  });

  return <Table rows={rows} />;
};
