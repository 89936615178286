export const ClockIcon = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-clock"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5525 18C5.5525 10.7572 11.424 4.88576 18.6668 4.88576C25.9096 4.88576 31.7811 10.7572 31.7811 18C31.7811 18.151 31.7785 18.3013 31.7735 18.451C32.4837 18.6852 33.1548 19.0055 33.7745 19.3995C33.8167 18.9386 33.8382 18.4719 33.8382 18C33.8382 9.62109 27.0457 2.82861 18.6668 2.82861C10.2878 2.82861 3.49536 9.62109 3.49536 18C3.49536 26.379 10.2878 33.1715 18.6668 33.1715C19.834 33.1715 20.9704 33.0397 22.062 32.7901C21.5874 32.2259 21.1808 31.6025 20.8548 30.9326C20.1433 31.0521 19.4123 31.1143 18.6668 31.1143C11.424 31.1143 5.5525 25.2429 5.5525 18ZM18.6667 6.68571C19.2348 6.68571 19.6953 7.14622 19.6953 7.71429V18C19.6953 18.5681 19.2348 19.0286 18.6667 19.0286H10.9525C10.3844 19.0286 9.92389 18.5681 9.92389 18C9.92389 17.4319 10.3844 16.9714 10.9525 16.9714H17.6382V7.71429C17.6382 7.14622 18.0987 6.68571 18.6667 6.68571ZM36.6667 27C36.6667 31.2605 33.2129 34.7143 28.9525 34.7143C24.692 34.7143 21.2382 31.2605 21.2382 27C21.2382 22.7395 24.692 19.2857 28.9525 19.2857C33.2129 19.2857 36.6667 22.7395 36.6667 27ZM33.1682 23.8434C33.5658 24.1417 33.6464 24.7058 33.3482 25.1034L28.9795 30.928C28.8194 31.1415 28.5727 31.2729 28.3062 31.2868C28.0396 31.3006 27.7807 31.1954 27.5993 30.9996L25.1114 28.3141C24.7736 27.9495 24.7954 27.38 25.16 27.0422C25.5246 26.7044 26.0941 26.7262 26.4319 27.0908L28.1867 28.9851L31.9082 24.0234C32.2064 23.6258 32.7706 23.5452 33.1682 23.8434Z"
      fill="currentColor"
    />
  </svg>
);
