import { ModalLayer, useModal } from ".";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { FunctionalComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import { useIsMobile } from "@/hooks";
import { Icon } from "@/icons";
import { Overlay } from "@/uikit";

import { useRerender } from "@/hooks/useRerender";

type ModalCardProps = {
  layer: ModalLayer;
  isLast?: boolean;
};

export const ModalCard: FunctionalComponent<ModalCardProps> = (
  { layer, isLast },
) => {
  const modalState = useModal();
  const [props, api] = useSpring(() => ({ y: 0 }));
  const cardRef = useRef<HTMLDivElement>();
  const [cardHeight, setCardHeight] = useState(0);
  const { counter: resizeCounter, rerender: resize } = useRerender();
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useIsMobile();

  const close = () => {
    modalState.closeLayer(layer.layerID, api);
  };

  const drag = useDrag(
    (dragData) => {
      const position = cardHeight - dragData.movement[1];

      if (!dragData.down) {
        if (position < cardHeight * 0.6) {
          return close();
        } else {
          return api.start({ y: cardHeight });
        }
      }

      api.start({ y: position });
    },
    { axis: "y", enabled: isMobile },
  );

  useEffect(() => {
    const root = document.documentElement;
    setIsOpen(layer.isOpen);

    if (layer.isOpen) {
      root.style.overflow = "hidden";
    } else {
      root.style.removeProperty("overflow");
      close();
    }
  }, [layer.isOpen]);

  useEffect(() => {
    if (!cardRef.current) {
      return;
    }

    const newHeight = cardRef.current.getBoundingClientRect().height;

    setCardHeight(newHeight);

    if (layer.isOpen && isMobile) {
      api.start({ to: { y: newHeight } });
    }
  }, [layer, layer.isOpen, isMobile, cardRef.current, resizeCounter]);

  const Contents = layer.contents;

  const controls = {
    close,
    resize,
  };

  return (
    <>
      {isLast ? (
        <Overlay
          closeCallback={() => {
            close();
          }}
          isOpen={true}
        />
      ) : null}
      <animated.div
        className="modal-card"
        data-mobile={isMobile}
        {...drag()}
        style={{
          top: isMobile ? props.y.to((y) => `calc(100% - ${y}px)`) : "unset",
        }}
        ref={cardRef}
        data-open={isOpen}
      >
        <button className="modal-close" onClick={close}>
          <Icon iconKey="close" />
        </button>
        <Contents controls={controls} />
      </animated.div>
    </>
  );
};
