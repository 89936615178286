export const CookieIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    id="icon-cookie"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M9.151 14.481a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM10.771 7.73a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM7.521 10.105a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0ZM13.888 14.124a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0ZM15.246 17.438a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
    />
    <path
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5.636 5.636A8.972 8.972 0 0 1 12 3c1.046 0 2.05.178 2.983.506a2.234 2.234 0 0 0 .442 3.445 3.127 3.127 0 0 0 2.957 5.083 2.232 2.232 0 0 0 2.337 2.206 8.99 8.99 0 0 1-2.355 4.124A8.972 8.972 0 0 1 12 21a8.972 8.972 0 0 1-6.364-2.636A8.972 8.972 0 0 1 3 12a8.972 8.972 0 0 1 2.636-6.364Z"
    />
  </svg>
);
