import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { InfoCard, Link, Text } from "@/uikit";

import "./style.css";

type SecurityProps = {};
export const Security: FunctionalComponent<SecurityProps> = ({}) => {
  return (
    <>
      <InfoCard title="Security ratings" sectionKey="security">
        <div className="security-card">
          <Icon iconKey="shield" />
          <div className="security-card-text">
            <Text className="security-title" variant="h3" weight="semibold">
              94.49 / 100{" "}
              <Text className="badge" embed variant="caption" weight="normal">
                High score
              </Text>
            </Text>
            <Text variant="subtitle" muted>
              7 highlights & 0 alerts
            </Text>
          </div>
        </div>
      </InfoCard>
      <Text muted variant="caption">
        Security info are provided by{" "}
        <Link href="https://skynet.certik.com/projects/tonstakers">Certik</Link>
        .
      </Text>
    </>
  );
};
