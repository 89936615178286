import { useEventListener } from ".";
import Big from "big.js";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { ComponentChildren } from "preact";
import { useEffect } from "preact/hooks";

import { FarmingData, NFTData } from "@/api/farming/farming";

import { useAccount } from "./useAccount";
import { tonClientAtom } from "./useTonstakers";

type TokenData = {
  name: string; // Token name (for display)
  contract: string; // Token contract address in raw format (0:...)
  decimals: number; // Decimals of the token
};

export type FarmData = {
  id: number; // Unique farm id
  token: TokenData; // Data of the staked token
  rewardTokens: TokenData[]; // Data of the reward tokens. Now, only one reward token is supported
  owner: string; // Name of the token owner (for display)
  logo: string; // Stake token logo URL (when farming data is retrieved, this is replaced with an image from tonapi)
  description: string | ComponentChildren; // Stake token description (for display)
  buyURL: string; // URL of where to buy token
  farmAddress: string; // Minter address in EQ... format (probably any format, but only this one has been tested)
  apy?: Big; // Don't set, fetched from the network
  tvl?: {
    // Don't set, fetched from the network
    native: Big;
    USD: Big;
  };
  farmingData?: FarmingData; // Don't set, fetched from the network
};

type Chain = "mainnet" | "testnet";
type Token =
  | "gemston"
  | "ston"
  | "punk"
  | "xrock"
  | "jetton"
  | "durev"
  | "web3";

const tokens: Record<Token, TokenData> = {
  gemston: {
    name: "Gemston",
    contract:
      "0:57e8af5a5d59779d720d0b23cf2fce82e0e355990f2f2b7eb4bba772905297a4",
    decimals: 9,
  },
  ston: {
    name: "STON",
    contract:
      "0:3690254dc15b2297610cda60744a45f2b710aa4234b89adb630e99d79b01bd4f",
    decimals: 9,
  },
  punk: {
    name: "$PUNK",
    contract:
      "0:9da73e90849b43b66dacf7e92b576ca0978e4fc25f8a249095d7e5eb3fe5eebb",
    decimals: 9,
  },
  xrock: {
    name: "XROCK",
    contract:
      "0:157c463688a4a91245218052c5580807792cf6347d9757e32f0ee88a179a6549",
    decimals: 9,
  },
  jetton: {
    name: "JetTon",
    contract:
      "0:105e5589bc66db15f13c177a12f2cf3b94881da2f4b8e7922c58569176625eb5",
    decimals: 9,
  },
  durev: {
    name: "durev",
    contract:
      "0:74d8327471d503e2240345b06fe1a606de1b5e3c70512b5b46791b429dab5eb1",
    decimals: 9,
  },
  web3: {
    name: "$WEB3",
    contract:
      "0:6d70be0903e3dd3e252407cbad1dca9d69fb665124ea74bf19d4479778f2ed8b",
    decimals: 3,
  },
};

const farmsBase: Record<Chain, FarmData[]> = {
  mainnet: [
    {
      id: 1,
      token: tokens.gemston,
      rewardTokens: [tokens.ston],
      owner: "STON.fi",
      logo: "/images/gemston_symbol.webp",
      description:
        "GEMSTON is an engagement token used to incentivize active participation in the STON.fi protocol.",
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=GEMSTON",
      farmAddress: "EQBh2Asg4Opnlgmkw25gpZ7IcmyNPJceLh_51o0lOGwGjvuc",
    },
    {
      id: 4,
      token: tokens.punk,
      rewardTokens: [tokens.punk],
      owner: "zero-address.ton",
      logo: "/images/punk.webp",
      description: (
        <>
          $PUNK is a GameFi utility token and a common currency in the Punk City
          ecosystem
        </>
      ),
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=PUNK",
      farmAddress: "EQDjQOGq_bx9pdjQJhQRK9Lux-YOUwDu1UNM8SevvbG25dEq",
    },
    {
      id: 5,
      owner: "xrocket.tg",
      token: tokens.xrock,
      rewardTokens: [tokens.xrock],
      logo: "https://static.xrocket.tg/xrock.png",
      description:
        "$XROCK is the utility token of xRocket, the first CEX exchange in Telegram with more than 4.5 million users",
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=XROCK",
      farmAddress: "EQA0R317P10bopg5YGnI4B9_cJc0jPbVwnLZu3JuMWdyNgly",
    },
    {
      id: 10,
      token: tokens.jetton,
      rewardTokens: [tokens.jetton],
      owner: "jetton.investment",
      logo: "/images/jetton.png",
      description:
        "$JetTon is utility token for JetTon Games Platform with 6M users",
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=JETTON",
      farmAddress: "EQA5BgnWFYQuKjDMgmqsYRTA1S3qjrF1MvmjG-BDEA19j0I9",
    },
    {
      id: 15,
      token: tokens.durev,
      rewardTokens: [tokens.durev],
      owner: "PovelDurev",
      logo: "/images/durev-logo.webp",
      description: "Povel Durev is a memecoin on the TON network!",
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=durev",
      farmAddress: "EQDw7u6CwkbIfzhfdxITAy09yqvAk59hyCdxbdQCR67ilyn-",
    },
    {
      id: 18,
      token: tokens.web3,
      rewardTokens: [tokens.web3],
      owner: "https://web3ton.io",
      logo: "/images/web3.png",
      description: "Utility token of TON DNS Club ecosystem on TON",
      buyURL:
        "https://app.ston.fi/swap?referral_address=UQAH0W08NGJnYrhxqMAJCrslz9sXcp16_CiJJYdBkgYj8uQe&ft=TON&tt=WEB3",
      farmAddress: "EQDMMSQsmGocIRUMxXL4MamEGasiANnV6GpKstApK45lVGwc",
    },
  ],
  testnet: [
    {
      id: 1,
      token: {
        name: "StakeToken",
        contract:
          "0:fe6d7245226274b9644a7c113aef84315a0571954a3407b0432c31d3d91dd097",
        decimals: 0,
      },
      rewardTokens: [
        {
          name: "RewardToken",
          contract:
            "0:7eb0006582ae355b88ef71bc520fcd4e87b3b5d95b40cc9d1e5ef018790351ab",
          decimals: 0,
        },
      ],
      owner: "FakeOwner",
      logo: "/images/fake-logo.webp",
      description:
        "The StakeToken token is utility token on TON Blockchain integrated into the core mechanics of the Fake protocol.",
      buyURL: "https://app.fake.fi",
      farmAddress: "EQBGy6YEkZzHpOc2r5aT4sdY9pS9h2u6ofVwf-WmNl91MyBo",
    },
  ],
};

export const farmsDataAtom = atom<FarmData[]>(farmsBase.mainnet);

export type FarmingWalletData = {
  stake: (amount: Big) => Promise<boolean>;
  claimRewards: (nftAddress: string, farmAddress: string) => Promise<boolean>;
  withdraw: (nftAddress: string, farmAddress: string) => Promise<boolean>;
  updateWallets: () => Promise<void>;
  userBalance: Big;
  userStake: NFTData[];
  farmData: FarmData;
  nextRoundReward: Big;
  stakedTokens: Big;
};

export type FarmingAccount = {
  loaded: boolean;
  isLoading: boolean;
  farms: FarmData[];
} & (
  | {
      connected: false;
      connect: () => Promise<void>;
    }
  | {
      connected: true;
      address: string;
      rawAddress: string;
      shortAddress: string;
      disconnect: () => Promise<void>;
      farmingWallets: FarmingWalletData[] | null;
    }
);

const farmingWalletsAtom = atom<FarmingWalletData[] | null>(null);

export const useFarmingWalletUpdate = () => {
  const account = useAccount();
  const setFarmingWallets = useSetAtom(farmingWalletsAtom);
  const tonClient = useAtomValue(tonClientAtom);
  const [farmsData, setFarmsData] = useAtom(farmsDataAtom);

  useEffect(() => {
    updateFarmingWallets();
  }, [account.connected, farmsData]);

  useEventListener(
    tonClient?.farmingSDK,
    "setupclient",
    () => {
      const sdk = tonClient?.farmingSDK;
      if (!sdk) {
        return;
      }

      setFarmsData(farmsBase[sdk.isTestnet ? "testnet" : "mainnet"]);
    },
    [tonClient?.farmingSDK],
  );

  const updateFarmingWallets = async () => {
    if (account.connected && tonClient) {
      const newFarmingWallets: FarmingWalletData[] = [];
      const farmingSDK = tonClient.farmingSDK;

      for (const farm of farmsData) {
        const userBalance = await farmingSDK.getAvailableBalance(
          farm.token.contract,
        );
        const userStake = await farmingSDK.getStake(farm.farmAddress);
        const stakedTokens = userStake.reduce(
          (acc, cur) => acc.add(cur.staked_tokens),
          Big(0),
        );

        newFarmingWallets.push({
          userBalance,
          userStake,
          stake: async (amount: Big) => {
            if (!farm.farmingData) {
              return false;
            }

            return farmingSDK.stake({
              amount: amount.toNumber(),
              balance: userBalance.toNumber(),
              farmAddress: farm.farmAddress,
              tokenContract: farm.token.contract,
              contractVersion: farm.farmingData.v,
              poolCount: farm.farmingData.poolCount,
              decimals: farm.token.decimals,
            });
          },
          withdraw: async (nftAddress: string) => {
            if (!farm.farmingData) {
              return false;
            }

            return farmingSDK.withdraw({
              nftAddress,
              contractVersion: farm.farmingData.v,
              poolCount: farm.farmingData.poolCount,
            });
          },
          claimRewards: async (nftAddress: string) => {
            if (!farm.farmingData) {
              return false;
            }

            return farmingSDK.claimRewards({
              nftAddress,
              contractVersion: farm.farmingData.v,
              poolCount: farm.farmingData.poolCount,
            });
          },
          farmData: farm,
          nextRoundReward: await farmingSDK.getDailyRewards(
            farm.farmAddress,
            stakedTokens.toNumber(),
          ),
          updateWallets: updateFarmingWallets,
          stakedTokens,
        });
      }

      setFarmingWallets(newFarmingWallets);
    }
  };

  return { updateWallets: updateFarmingWallets };
};

export const useFarmingAccount = (): FarmingAccount => {
  const account = useAccount();
  const farmingWallets = useAtomValue(farmingWalletsAtom);
  const farmsData = useAtomValue(farmsDataAtom);

  if (!account.connected) {
    return {
      connected: false,
      isLoading: account.isLoading,
      loaded: account.loaded,
      farms: farmsData,
      connect: account.connect,
    };
  }

  return {
    connected: true,
    isLoading: account.isLoading,
    loaded: account.loaded,
    farms: farmsData,
    disconnect: account.disconnect,
    farmingWallets,
    address: account.address,
    rawAddress: account.rawAddress,
    shortAddress: account.shortAddress,
  };
};
