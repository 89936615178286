import CryptoJS from "crypto-js";

interface AdditionalData {
  [key: string]: any;
}

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

function encryptWalletAddress(walletAddress: string): string {
  const passphrase = import.meta.env.VITE_ANALYTICS_ENCRYPTION_PASSPHRASE;
  return CryptoJS.AES.encrypt(walletAddress, passphrase).toString();
}

function sendGtmEvent(eventName: string, eventData: object = {}): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventData,
    ab_param: import.meta.env.VITE_BRANCH,
  });
}

export function sendEncryptedWalletEvent(
  eventName: string,
  walletAddress: string | null = "",
  additionalData: AdditionalData = {},
): void {
  const encryptedAddress = walletAddress
    ? encryptWalletAddress(walletAddress)
    : null;
  sendGtmEvent(eventName, {
    wallet_address: encryptedAddress,
    ...additionalData,
  });
}

export function sendSimpleEvent(
  eventName: string,
  eventData: AdditionalData = {},
): void {
  sendGtmEvent(eventName, eventData);
}
