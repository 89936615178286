import Big from "big.js";

export const getEarnings = (apy: Big, amount: Big, roundRoi: Big) => {
  apy = apy.add(1);

  const qpy = Big(Math.pow(apy.toNumber(), 0.25));

  const roundYield = roundRoi;
  const mpy = roundYield.pow(40);

  const yearly = apy.sub(1).mul(amount);
  const quarterly = qpy.sub(1).mul(amount);
  const monthly = mpy.sub(1).mul(amount);
  const perRound = roundYield.sub(1).mul(amount);

  return {
    yearly,
    quarterly,
    monthly,
    perRound,
  };
};
