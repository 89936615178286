import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import { Icon } from "@/icons";
import { Text } from "@/uikit";

export const StakeNFTCard: FunctionalComponent = () => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    setShown(true);
  }, []);

  return (
    <div className="stake-nft-card gap-horizontal" data-shown={shown}>
      <Icon iconKey="nft" />
      <Text variant="subtitle">
        You will receive an NFT, which will be stored in your wallet during the
        unstaking process. Please do not transfer it to anyone.
      </Text>
    </div>
  );
};
