import { useAtom, useSetAtom } from "jotai";
import { useEffect } from "preact/hooks";

import { useBlockchainData, useRates } from "@/api";
import { useMobileState, useScrollState } from "@/hooks";

import { onboardedAtom } from "@/state/page";
import { useModal } from "@/uikit/Modal";

import { useThemeColor } from "./useThemeColor";
import { tonClientAtom } from "./useTonstakers";
import { useWallet } from "./useWallet";

const tonstakersChunk = import("@/api/client");

export const useInit = () => {
  const setTonstakersClient = useSetAtom(tonClientAtom);
  const { openOnboarding } = useModal();
  const [onboarded, setOnboarded] = useAtom(onboardedAtom);

  useEffect(() => {
    if (!onboarded) {
      openOnboarding();
      setOnboarded(true);
    }
  }, [onboarded]);

  useEffect(() => {
    tonstakersChunk.then(setTonstakersClient);
  }, []);

  useWallet();

  useScrollState();
  useMobileState();
  useBlockchainData();
  useRates();
  useThemeColor();
};
