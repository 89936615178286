import { FunctionalComponent } from "preact";
import { useState } from "preact/hooks";

import { Icon } from "@/icons";
import { Text } from "@/uikit";

import { FAQPointData } from "./points";

export const FAQPoint: FunctionalComponent<FAQPointData> = (
  { children, title },
) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((x) => !x);

  return (
    <div className="faq-point" data-open={isOpen}>
      <div className="faq-point-icon" onClick={toggle}>
        <Icon iconKey="chevron" />
      </div>
      <div className="faq-point-title" onClick={toggle}>
        <Text>{title}</Text>
      </div>
      <div className="faq-point-content">
        <Text>{children}</Text>
      </div>
    </div>
  );
};
