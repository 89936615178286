import { IconSprite } from "@/icons";
import { CurrentPage } from "@/pages";
import { Modal } from "@/uikit";

import "./app.css";
import "@/utils/tma";

import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from "@sentry/browser";

import { CookiePopup } from "@/components";

import { useInit } from "./hooks/useInit";

init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [browserTracingIntegration(), replayIntegration()],
});

export function App() {
  useInit();

  return (
    <>
      <CurrentPage />
      <IconSprite />
      <Modal />
      <CookiePopup />
    </>
  );
}
