import Big from "big.js";

import { EarnOfferData } from "@/components/EarnOffer";
import { formatNumber } from "@/utils/numbers";

export const getOfferDescription = (description: string) => {
  return (apy: number) => {
    const apyBig = Big(apy);
    return `${formatNumber(apyBig, false, 2, false)}% – ${description}`;
  };
};

export const offers: (EarnOfferData & { url: string })[] = [
  {
    icon: "/images/evaa.svg",
    name: "EVAA Protocol",
    sections: [
      {
        color: "blue",
        description: getOfferDescription("EVAA XP Rewards"),
        value: 2,
      },
      {
        color: "cyan",
        description: getOfferDescription("tsTON EVAA Providing APY"),
        value: 10,
      },
    ],
    url: "https://app.evaa.finance?referral=UQDBRkHJXEm-keU8aENGUFoAhCvKJFldqwtpsM2EwSlZ6OkE",
  },
  {
    icon: "/images/stonfi.svg",
    name: "STON",
    sections: [
      {
        color: "blue",
        description: getOfferDescription("some STON bonus"),
        value: 20,
      },
    ],
    url: "https://app.ston.fi/",
  },
  {
    icon: "/images/dedust.svg",
    name: "DeDust.io",
    sections: [
      {
        color: "cyan",
        description: getOfferDescription("cyan dedust bonus (very big)"),
        value: 34.5,
      },
    ],
    url: "https://dedust.io/",
  },
];
