import Big from "big.js";
import { FunctionalComponent } from "preact";

import { Button, InfoCard, Table, TextButton } from "@/uikit";

import { useAccount } from "@/hooks/useAccount";
import { useCurrency } from "@/hooks/useCurrency";
import { Currency } from "@/state/currency";
import { useModal } from "@/uikit/Modal";
import { RowData } from "@/uikit/TableRow";
import { formatAmount, formatAmountTitled } from "@/utils/numbers";

import "./style.css";

import { useSetAtom } from "jotai";

import { pageAtom } from "@/state/page";

import { RewardCard } from "./rewardCard";

export const Account: FunctionalComponent<{ page: any }> = () => {
  const account = useAccount();
  const currency = useCurrency();
  const modal = useModal();
  const setPage = useSetAtom(pageAtom);

  if (!account.connected) {
    return (
      <>
        <Button onClick={account.connect}>Connect account</Button>
      </>
    );
  }

  const tableValue = (
    amount: Big,
    prefix: string = "",
    baseCurrency?: Currency,
    isCurrency: boolean = true,
  ): RowData["right"] => {
    return formatAmountTitled({
      amount,
      fracDigits: currency.base === "USD" ? 2 : 3,
      prefix,
      currency: baseCurrency || currency.base,
      tonSuffix: false,
      isCurrency,
    });
  };

  const accountTable: RowData[] = [];

  accountTable.push({
    left: { text: "Available to stake" },
    right: tableValue(
      currency.convert(account.userBalance, currency.base, "TON"),
    ),
  });

  accountTable.push({
    left: { text: "Staked" },
    right: tableValue(
      currency.convert(account.userStaked, currency.base, "TON"),
    ),
  });

  accountTable.push({
    left: { text: "Staked, tsTON" },
    right: tableValue(
      currency.convert(account.userStaked, "tsTON", "TON"),
      "",
      "tsTON",
      false,
    ),
  });

  if (account.userStaked.gt(0)) {
    accountTable.push({
      left: { text: "Next round rewards" },
      right: tableValue(
        currency.convert(account.nextRoundReward, currency.base, "TON"),
        account.nextRoundReward.gt(Big(1).div(1000)) ? "+" : "",
      ),
    });
  }

  const currentRewards = currency.getProjectedEarnings(account.userStaked);
  const potentialRewards = currency.getProjectedEarnings(
    account.userStaked.add(account.userBalance),
  );

  const isAmoundValid = !account.getError("stake", account.userBalance);

  return (
    <>
      <InfoCard title={account.shortAddress} sectionKey="account">
        <Table rows={accountTable} />
      </InfoCard>
      <InfoCard title="Your current rewards" sectionKey="rewards">
        <RewardCard {...currentRewards} />
      </InfoCard>
      {isAmoundValid ? (
        <InfoCard title="Potential rewards" sectionKey="rewards">
          <RewardCard {...potentialRewards} />
          <TextButton
            buttonType="secondary"
            className="stake-more-button"
            onClick={() => modal.openStakeModal(account.userBalance, "stake")}
          >
            Add {formatAmount({ amount: account.userBalance, fracDigits: 2 })}
          </TextButton>
        </InfoCard>
      ) : undefined}
      <div className="logout-button">
        <TextButton
          onClick={() => {
            account.disconnect();
            setPage("stake");
          }}
          buttonType="outline"
        >
          Log out
        </TextButton>
      </div>
    </>
  );
};
