import { atomWithStorage } from "jotai/utils";

import { Button, Link, Text } from "@/uikit";

import "./style.css";

import { useAtom } from "jotai";
import { FunctionalComponent } from "preact";

import { Icon } from "@/icons";

type CookiePopupProps = {};

const cookieShownAtom = atomWithStorage("cookiePopup", true);

export const CookiePopup: FunctionalComponent<CookiePopupProps> = ({}) => {
  const [cookieShown, setCookieShown] = useAtom(cookieShownAtom);

  if (!cookieShown) {
    return null;
  }

  return (
    <div class="cookie-popup">
      <Icon iconKey="cookie" />
      <div>
        <Text>
          We&nbsp;use cookie to&nbsp;make your experience on&nbsp;our website
          the best. For&nbsp;more info read{" "}
          <Link href="https://tonstakers.com/privacy/">Privacy policy</Link>
        </Text>
      </div>
      <Button className="close-cookie" onClick={() => setCookieShown(false)}>
        <Icon iconKey="close" />
      </Button>
    </div>
  );
};
