import Big, { BigSource } from "big.js";

import { Currency } from "@/state/currency";

Big.DP = 20;

const buildMap = () => {
  const parts = formatToParts(100000.123);
  const partMap: Record<string, string> = {};

  parts.forEach((part) => {
    if (["group", "decimal"].includes(part.type)) {
      partMap[part.type] = part.value;
    }
  });
  return partMap;
};

export const cleanUp = (numberString: string): string => {
  return numberString
    .replaceAll(/\s+/g, "")
    .replaceAll(partMap.group, "")
    .replace(partMap.decimal, ".");
};

export const parseFloatLocalized = (numberString: string): Big => {
  return Big(cleanUp(numberString));
};

export const getFinalDecimal = (numberString: string): string => {
  const parts = numberString.split(partMap.decimal);

  return parts.length === 2 && parts[1] === "" ? partMap.decimal : "";
};

export const formatToParts = (num: number): Intl.NumberFormatPart[] => {
  return formatter.formatToParts(num);
};

export const formatFloatLocalized = (
  number: Big,
  fracDigits: number = 4,
  stripZeros: boolean = true,
  minDigits: number = 0,
  retainGroup: boolean = false,
): string => {
  const result = [];

  for (const part of formatToParts(number.toNumber())) {
    if (part.type === "fraction") {
      let value = part.value.slice(0, fracDigits);

      if (stripZeros) {
        value = value.replace(/0+$/g, "");
      }

      value = value.padEnd(minDigits, "0");

      if (value) {
        result.push(value);
      }

      continue;
    }

    if (part.type === "decimal") {
      result.push(".");
      continue;
    }

    if (part.type === "group") {
      if (retainGroup) {
        result.push(" ");
      }
      continue;
    }

    result.push(part.value);
  }

  let formatted = result.join("");

  if (formatted.endsWith(".")) {
    formatted = formatted.slice(0, -partMap.decimal.length);
  }

  return formatted;
};

export const formatNumber = (
  n: Big,
  round: boolean = false,
  fracDigits: number = 4,
  stripZeros: boolean = true,
  retainGroup: boolean = false,
): string => {
  if (round) {
    n.round();
  }

  if (n.add(1).gt(1e6)) {
    return formatLarge(n, fracDigits, stripZeros, retainGroup);
  }

  return formatFloatLocalized(n, fracDigits, stripZeros, 0, retainGroup);
};

const largeSuffixes = [
  "",
  "K",
  "M",
  "B",
  "T",
  "q",
  "Q",
  "s",
  "S",
  "o",
  "n",
  "d",
  "U",
  "D",
];

export const formatLarge = (
  n: Big,
  fracDigits: number = 4,
  stripZeros: boolean = true,
  retainGroup: boolean = false,
): string => {
  let suffixPower = 0;

  while (n.gt(1000)) {
    if (suffixPower + 1 === largeSuffixes.length) {
      break;
    }

    n = n.div(1000);
    suffixPower++;
  }
  let amountString = formatFloatLocalized(
    n,
    fracDigits,
    stripZeros,
    0,
    retainGroup,
  );

  return `${amountString}${largeSuffixes[suffixPower]}`;
};

const formatter = Intl.NumberFormat(undefined, { minimumFractionDigits: 10 });
const partMap = buildMap();

const numberRegex = /^(?:[1-9]\d{0,14}|0)(?:\.\d{0,9})?$/g;

export const isNumber = (s: string) => {
  return !!cleanUp(s).match(numberRegex);
};

export const fromNano = (n: BigSource) => {
  return Big(n).div(1000000000);
};

export const fromDecimals = (n: BigSource, decimals: number) => {
  return Big(n).div(10 ** decimals);
};

export const clamp = (min?: BigSource, value?: Big, max?: BigSource): Big => {
  if (value === undefined) {
    return Big(0);
  }

  if (min !== undefined && value.lt(min)) {
    return Big(min);
  }

  if (max !== undefined && value.gt(max)) {
    return Big(max);
  }

  return value;
};

export type CurrencySymbol = {
  prefix?: string;
  suffix?: string;
  tonSuffix?: string;
};

export const currencySymbols: Record<Currency, CurrencySymbol> = {
  USD: {
    prefix: "$",
    tonSuffix: " in TON",
  },
  TON: {
    suffix: " TON",
  },
  tsTON: {
    suffix: " tsTON",
  },
  tsTONProjected: {
    suffix: " tsTON (projected)",
  },
};

export const getSymbols = (base: string): CurrencySymbol => {
  let symbols: CurrencySymbol;

  if (base in currencySymbols) {
    symbols = currencySymbols[base as Currency];
  } else {
    symbols = {
      suffix: base ? " " + base : "",
    };
  }

  return symbols;
};

export const formatCurrency = (
  formatted: string,
  currency: string,
  tonSuffix = true,
) => {
  const symbols = getSymbols(currency);

  const parts: string[] = [
    symbols.prefix || "",
    formatted,
    symbols.suffix || "",
    tonSuffix && currency !== "TON" ? " in TON" : "",
  ];

  return parts.join("");
};

type AmountFormatParams = {
  amount?: Big;
  isPrecise?: boolean;
  fracDigits?: number;
  prefix?: string;
  currency?: string;
  tonSuffix?: boolean;
  stripZeros?: boolean;
  isCurrency?: boolean;
  bottomEdge?: BigSource;
  bottomEdgeString?: string;
  placeholder?: Big;
  retainGroup?: boolean;
};

export const formatAmount = (
  {
    amount,
    isPrecise = false,
    fracDigits = 3,
    prefix = "",
    currency = "TON",
    tonSuffix = true,
    stripZeros = false,
    isCurrency = true,
    bottomEdge,
    bottomEdgeString,
    placeholder,
    retainGroup = false,
  }: AmountFormatParams,
) => {
  amount = amount || placeholder || Big(0);

  let formattedAmount: string;

  if (bottomEdge && amount.lt(bottomEdge)) {
    formattedAmount =
      bottomEdgeString || `<${formatNumber(Big(bottomEdge), false, 20, true)}`;
  } else {
    formattedAmount = isPrecise
      ? formatFloatLocalized(amount, 10, true, 0, retainGroup)
      : formatNumber(amount, false, fracDigits, stripZeros, retainGroup);
  }

  if (!isCurrency) {
    return prefix + formattedAmount;
  }

  return `${prefix}${formatCurrency(formattedAmount, currency, tonSuffix)}`;
};

export const formatAmountTitled = (
  params: AmountFormatParams,
): { text: string; title: string } => {
  return {
    text: formatAmount({
      ...params,
      fracDigits: params.fracDigits || 2,
      stripZeros: true,
    }),
    title: params.amount
      ? formatAmount({
          ...params,
          fracDigits: undefined,
          isPrecise: true,
          stripZeros: true,
        })
      : "",
  };
};

type WordForms = [string, string];

const inflect = (n: number, forms: WordForms, full?: boolean) => {
  const n100 = n % 100;

  if (!full) {
    forms = forms.map((x) => x[0]) as WordForms;
  }

  if (n100 >= 10 && n100 <= 20) {
    return forms[1];
  }

  n %= 10;

  if (n === 1) {
    return forms[0];
  }

  return forms[1];
};

const timePeriods = [86400, 3600, 60, 1];
const timePeriodNames: WordForms[] = [
  ["day", "days"],
  ["hour", "hours"],
  ["minute", "minutes"],
  ["second", "seconds"],
];

export const formatTime = (seconds: number, full?: boolean) => {
  let i = 0;

  for (; i < timePeriods.length; i++) {
    if (seconds > timePeriods[i]) {
      break;
    }
  }

  if (i === timePeriods.length) {
    i--;
  }

  const result: string[] = timePeriods
    .map((p, i) =>
      Math.floor((!i ? seconds : seconds % timePeriods[i - 1]) / p),
    )
    .map((x, i) => [
      x,
      [x, inflect(x, timePeriodNames[i], full)].join(full ? " " : ""),
    ])
    .filter((x) => x[0] !== 0)
    .map((x) => x[1] as string);

  return result.join(" ");
};
