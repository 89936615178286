import { Text } from "@/uikit";

import "./style.css";

import { ComponentChildren, FunctionalComponent } from "preact";

import { BulletSeparator } from "@/components";

type AmountErrorProps = {
  message: string;
  visible: boolean;
  additional?: ComponentChildren;
};

export const AmountError: FunctionalComponent<AmountErrorProps> = (
  { message, visible, additional },
) => {
  return (
    <Text className="input-subtitle conditional-line" data-visible={visible}>
      <Text embed className="error">
        {message}
      </Text>
      {additional && (
        <>
          <BulletSeparator />
          {additional}
        </>
      )}
    </Text>
  );
};
