import { FunctionalComponent } from "preact";

import { Loader, Text } from "@/uikit";

type Point = {
  title: string;
  subtitle: string;
  value: number;
  tooltip: string;
};

export const ProtocolDetailsPoint: FunctionalComponent<Point> = (
  { title, subtitle, value, tooltip },
) => {
  return (
    <div className="protocol-details-point">
      <Loader checkValue={value}>
        <Text variant="h3" weight="semibold" title={tooltip}>
          {title}
        </Text>
      </Loader>
      <Text variant="subtitle">{subtitle}</Text>
    </div>
  );
};
