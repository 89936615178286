import "./style.css";

import { animated, useSpring } from "@react-spring/web";
import clsx from "clsx";
import { FunctionalComponent } from "preact";
import { Inputs, useEffect, useRef } from "preact/hooks";

import { Icon } from "@/icons";
import { Card, Loader, Text } from "@/uikit";

type OfferProps = {
  className?: string;
  icon: string;
  isFirst?: boolean;
  isOpen: boolean;
  offerTitle: string;
  setIsOpen: (value: boolean) => void;
  titles?: [string, string, string];
  values: [string, string];
  valuesLoaded: [boolean, boolean];
  isActive?: boolean;
  deps?: Inputs;
};

export const OfferTitles: FunctionalComponent<{
  titles: [string, string, string];
}> = ({ titles }) => {
  return (
    <div class="offer-table-row titles offer-titles">
      {titles.map((title) => (
        <Text muted key={title}>
          {title}
        </Text>
      ))}
    </div>
  );
};

export const Offer: FunctionalComponent<OfferProps> = (
  {
    children,
    className,
    icon,
    isFirst,
    isOpen,
    isActive,
    offerTitle,
    setIsOpen,
    titles,
    values,
    valuesLoaded,
    deps,
  },
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lastContentSize = useRef<number>(0);
  const [props, api] = useSpring(() => ({
    from: {
      maxHeight: 52,
      opacity: 0,
    },
  }));

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const element = containerRef.current;
    let contentSize: number;

    const headingSize = element.children.item(0)!.scrollHeight;

    if (!isOpen) {
      contentSize = headingSize;
    } else {
      contentSize = element.scrollHeight;
    }

    if (lastContentSize.current !== contentSize) {
      api.start({
        from: { maxHeight: lastContentSize.current },
        to: { maxHeight: contentSize, opacity: isOpen ? 1 : 0 },
        immediate: !lastContentSize.current,
      });

      lastContentSize.current = contentSize;
    }
  }, [isOpen, children, ...(deps || [])]);

  return (
    <>
      {isFirst && titles ? <OfferTitles titles={titles} /> : undefined}
      <animated.div
        class={clsx("offer gap-vertical", className)}
        data-open={isOpen}
        ref={containerRef}
        style={{
          maxHeight: props.maxHeight,
        }}
      >
        <Card
          className="offer-info offer-table-row"
          onClick={() => setIsOpen(!isOpen)}
          active={isActive}
        >
          <div>
            <img class="token-logo" src={icon} />
            <Text>{offerTitle}</Text>
          </div>
          <span>
            <Loader checkValue={valuesLoaded[0]}>
              <Text embed>{values[0]}</Text>
            </Loader>
          </span>
          <span>
            <Loader checkValue={valuesLoaded[1]}>
              <Text embed>{values[1]}</Text>
            </Loader>
          </span>
          <div className="chevron-button">
            <Icon iconKey="chevron" />
          </div>
        </Card>
        <animated.div
          className="offer-contents gap-vertical"
          style={{ opacity: props.opacity }}
        >
          {children}
        </animated.div>
      </animated.div>
    </>
  );
};
