import { render } from "preact";

import "@/styles/colors.css";
import "@/styles/fonts.css";
import "@/styles/reset.css";
import "./index.css";

import { App } from "./app";

render(<App />, document.getElementById("app")!);
