import clsx from "clsx";
import { FunctionalComponent } from "preact";

import { ClassNamed } from "@/types/classname";

import "./style.css";

type PromoCardProps = ClassNamed<{
  variant: "purple" | "yellow" | "cyan";
}>;

export const PromoCard: FunctionalComponent<PromoCardProps> = (
  { children, variant, className },
) => {
  return (
    <div
      className={clsx("promocard", className, "gap-vertical")}
      data-variant={variant}
    >
      {children}
    </div>
  );
};
