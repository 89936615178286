import { ComponentProps, FunctionalComponent } from "preact";

import "./style.css";

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type HeadingComponent = `h${HeadingLevel}`;

type HeadingProps = {
  level: HeadingLevel;
} & ComponentProps<"h1">;

export const Heading: FunctionalComponent<HeadingProps> = (
  { level, children, ...rest },
) => {
  const Component: HeadingComponent = `h${level}`;

  return <Component {...rest}>{children}</Component>;
};
