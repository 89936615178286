import { useState } from "preact/hooks";

import { Icon } from "@/icons";
import { Overlay, Text } from "@/uikit";

import "./style.css";

export type SelectItem<K extends string> = {
  key: K;
  name: string;
};

type SelectProps<K extends string> = {
  items: SelectItem<K>[];
  selected: K;
  onSelect: (key: K) => void;
};

export const Select = <K extends string>(
  { items, selected, onSelect }: SelectProps<K>,
) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <div className="select" data-open={isOpen}>
      <Overlay closeCallback={close} isOpen={isOpen} />
      <button className="select-button" onClick={open}>
        <Text variant="caption" weight="semibold">
          {selected.toUpperCase()}
        </Text>
        <Icon iconKey="chevron" />
      </button>
      <div className="select-items">
        {items.map((item) => (
          <button
            onClick={() => {
              onSelect(item.key);
              close();
            }}
            key={item.key}
            className="select-item"
          >
            <Text variant="caption" weight="semibold">
              {item.name}{" "}
              <Text embed muted>
                {item.key}
              </Text>
            </Text>
            <div className="tick" data-visible={item.key === selected}>
              <Icon iconKey="tick" />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
