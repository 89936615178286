import clsx from "clsx";
import { FunctionalComponent } from "preact";

import { Card, Text } from "@/uikit";

import "./style.css";

type InfoCardProps = {
  title: string;
  sectionKey?: string;
};

export const InfoCard: FunctionalComponent<InfoCardProps> = (
  { title, children, sectionKey },
) => {
  return (
    <section
      className={clsx("info-card", sectionKey && `${sectionKey}-section`)}
    >
      <Text variant="h4" weight="semibold" className="info-card-title">
        {title}
      </Text>
      <Card>{children}</Card>
    </section>
  );
};
