import Big from "big.js";
import { FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";

import { CurrencyFormat, RoundCountdown, StakingInfo } from "@/components";
import { Button, Heading, Text, TextButton } from "@/uikit";

import { Account, useAccount } from "@/hooks/useAccount";
import { useCurrency } from "@/hooks/useCurrency";
import { Action } from "@/types/action";
import { ModalControls } from "@/uikit/Modal";
import { capitalize } from "@/utils/capitalize";
import { formatFloatLocalized, formatNumber } from "@/utils/numbers";

import "./style.css";

import { sendEncryptedWalletEvent } from "@/utils/analytics";

import { ResultModal } from "./resultModal";

type StakeModalProps = {
  action: Action;
  amount: Big;
  controls: ModalControls;
};

type ConfirmActionProps = {
  action: Action;
  bestRate: boolean;
  account: Account;
  setStatus: (value: boolean) => void;
  amount: Big;
};

export const confirmAction = async (
  { action, bestRate, account, setStatus, amount }: ConfirmActionProps,
) => {
  if (!account.connected) {
    return;
  }

  sendEncryptedWalletEvent(`app_${action}`, account.rawAddress);

  let request: Promise<boolean>;

  if (action === "stake") {
    request = account.stake(amount);
  } else {
    request = account.unstake(amount, bestRate);
  }

  const status = await request;

  sendEncryptedWalletEvent(
    `app_${action}_${status ? "success" : "error"}`,
    account.rawAddress,
  );
  setStatus(status);
  account.updateBalances();
};

export const StakeModal: FunctionalComponent<StakeModalProps> = (
  { action, amount, controls },
) => {
  const currency = useCurrency();
  const account = useAccount();
  const [confirmed, setConfirmed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [bestRate, setBestRate] = useState(false);

  useEffect(() => {
    controls.resize();
  }, [confirmed, success, bestRate]);

  if (!account.connected) {
    return null;
  }

  const confirmActionInner = () => {
    confirmAction({
      account,
      action,
      amount,
      bestRate,
      setStatus: setSuccess,
    }).then(() => setConfirmed(true));
  };

  if (confirmed) {
    return (
      <ResultModal
        action={action}
        amount={amount}
        bestRate={bestRate}
        controls={controls}
        success={success}
      />
    );
  }

  const usdAmount = currency.fromTON(amount);
  const bestRateAmount = currency.convert(
    currency.convert(amount, "tsTON", "TON"),
    "TON",
    "tsTONProjected",
  );

  return (
    <>
      <div className="stake-modal-header gap-vertical">
        <Text variant="h4" weight="semibold">
          Confirm {action}
        </Text>
        <Heading level={1}>
          <CurrencyFormat tonSuffix={false} amount={usdAmount}>
            {formatNumber(usdAmount)}
          </CurrencyFormat>
        </Heading>
        {currency.base !== "TON" ? (
          <Text muted>
            <CurrencyFormat base="TON" amount={amount}>
              {formatNumber(amount)}
            </CurrencyFormat>
          </Text>
        ) : null}
        {action === "unstake" ? (
          <div className="stake-modal-rate-select gap-horizontal">
            <Button
              className="stake-modal-rate"
              data-selected={!bestRate}
              onClick={() => setBestRate(false)}
            >
              <Text weight="semibold" className="unstake-type">
                Instant
              </Text>
              <Text>
                You'll get{" "}
                <Text weight="semibold" embed>
                  {formatFloatLocalized(amount, 2)} TON
                </Text>{" "}
                instantly.
              </Text>
            </Button>
            <Button
              className="stake-modal-rate"
              data-selected={bestRate}
              onClick={() => setBestRate(true)}
            >
              <Text weight="semibold" className="unstake-type">
                Best rate
              </Text>
              <Text>
                You'll get{" "}
                <Text weight="semibold" embed>
                  {formatFloatLocalized(bestRateAmount, 2)} TON
                </Text>{" "}
                in&nbsp;
                <RoundCountdown />.{" "}
              </Text>
            </Button>
          </div>
        ) : undefined}
      </div>
      <div className="stake-modal-content gap-vertical">
        <Text variant="h4" weight="semibold">
          {capitalize(action)} details
        </Text>
        <StakingInfo
          amount={amount}
          action={action}
          tsTonPrice={currency.convert(Big(1), "TON", "tsTON")}
          fee={action === "stake"}
          withdrawalFee={action === "unstake"}
          lose={Big(0)}
          staked={Big(0)}
        />
      </div>
      <div className="stake-modal-confirm">
        <TextButton onClick={confirmActionInner} disabled={!account.connected}>
          Confirm to {action}
        </TextButton>
      </div>
    </>
  );
};
