export const CloseIcon = (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-close"
  >
    <g
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M11.4546 11.4546L24.1364 24.1364" />
      <path d="M11.4546 24.1364L24.1364 11.4545" />
    </g>
  </svg>
);
