import { ComponentChildren, FunctionalComponent } from "preact";

import "./style.css";

import Big from "big.js";

import { FAQ, FarmingFarm, FarmingWallet } from "@/components";
import { Heading, Spinner, Text } from "@/uikit";

import { useFarmingAccount } from "@/hooks/useFarmingAccount";
import { fromDecimals } from "@/utils/numbers";

export const Farming: FunctionalComponent<{ page: any }> = () => {
  const farmingAccount = useFarmingAccount();

  const content: ComponentChildren[] = [];

  if (farmingAccount.connected) {
    if (farmingAccount.farmingWallets !== null) {
      const hasStake = farmingAccount.farmingWallets.some((wallet) =>
        wallet.stakedTokens.gt(0),
      );

      if (hasStake) {
        content.push(
          <Text
            variant="h4"
            weight="semibold"
            key="portfolio-heading"
            className="farm-portfolio-heading"
          >
            Your active collections
          </Text>,
        );
        farmingAccount.farmingWallets
          .filter((wallet) => wallet.stakedTokens.gt(0))
          .forEach((wallet, i) => {
            content.push(
              <FarmingWallet
                wallet={wallet}
                key={`wallet-${wallet.farmData.farmAddress}`}
                index={i}
              />,
            );
          });
      }
    } else {
      content.push(
        <div class="farming-portfolio-spinner">
          <Spinner />
        </div>,
      );
    }
  }

  content.push(
    <Text
      variant="h4"
      weight="semibold"
      key="farms"
      className="farm-farms-heading"
    >
      Tokens
    </Text>,
  );

  farmingAccount.farms.forEach((farm, i) => {
    const wallet = farmingAccount.connected
      ? farmingAccount.farmingWallets?.[i]
      : undefined;

    const balance = fromDecimals(
      wallet?.userBalance || Big(0),
      wallet?.farmData.token.decimals || 9,
    );

    content.push(
      <FarmingFarm
        balance={balance}
        farm={farm}
        stake={async (amount: Big) => {
          if (!wallet) {
            return false;
          }
          return wallet.stake(amount);
        }}
        index={i}
      />,
    );
  });

  return (
    <>
      <Heading level={1} className="farming-header">
        Stake your desired tokens and earn instant rewards
      </Heading>

      {content}

      <FAQ type="farm" />
    </>
  );
};
