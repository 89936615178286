import Big from "big.js";
import { atom, useAtomValue, useSetAtom } from "jotai";

import { ERROR_MESSAGES } from "@/constants/errorMessages";
import { addressAtom } from "@/state/api";
import { userBalanceAtom, userStakedAtom } from "@/state/api/account";
import { Action } from "@/types/action";
import { getShortAddress } from "@/utils/address";
import { sendSimpleEvent } from "@/utils/analytics";
import { clamp, fromNano } from "@/utils/numbers";

import { useCurrency } from "./useCurrency";
import { tonClientAtom } from "./useTonstakers";

type ActionLimits = Record<Action, Big>;

export type Account = {
  getError: (action: Action, amount: Big) => string | undefined;
  loaded: boolean;
  isLoading: boolean;
} & (
  | {
      connected: false;
      connect: () => Promise<void>;
      min: ActionLimits;
      max: ActionLimits;
    }
  | {
      connected: true;
      userBalance: Big;
      userStaked: Big;
      tsTONBalance: Big;
      address: string;
      rawAddress: string;
      shortAddress: string;
      stake: (amount: Big) => Promise<boolean>;
      unstake: (amount: Big, bestRate: boolean) => Promise<boolean>;
      min: ActionLimits;
      max: ActionLimits;
      disconnect: () => Promise<void>;
      updateBalances: () => Promise<void>;
      nextRoundReward: Big;
    }
);

const balanceUpdatedAtom = atom<boolean>(false);

export const useBalanceUpdate = () => {
  const setUserBalance = useSetAtom(userBalanceAtom);
  const setUserStaked = useSetAtom(userStakedAtom);
  const tonClient = useAtomValue(tonClientAtom);
  const setBalanceUpdated = useSetAtom(balanceUpdatedAtom);
  const wallet = tonClient?.tonConnectUI.wallet;

  const updateBalances = async () => {
    const sdk = tonClient?.tonstakers;
    const wallet = tonClient?.tonConnectUI.wallet;

    if (!sdk || !wallet) {
      return;
    }

    if (sdk.ready) {
      const availableBalance = Big(await sdk.getAvailableBalance());

      setUserBalance(clamp(0, fromNano(availableBalance)));

      let tsTONStaked: Big;

      try {
        tsTONStaked = Big(await sdk.getStakedBalance());
      } catch (e) {
        console.error(e);
        tsTONStaked = Big(0);
      }

      setUserStaked(clamp(0, fromNano(tsTONStaked)));

      setBalanceUpdated(true);
    }
  };

  const triggerUpdate = async () => {
    if (!tonClient) {
      return;
    }
    await updateBalances();
  };

  return {
    updateBalances,
    triggerUpdate,
    wallet,
  };
};

export const useAccount = (): Account => {
  const rawAddress = useAtomValue(addressAtom);
  const balanceUpdated = useAtomValue(balanceUpdatedAtom);
  const userBalance = useAtomValue(userBalanceAtom);
  const userStaked = useAtomValue(userStakedAtom);
  const currency = useCurrency();
  const { updateBalances, triggerUpdate, wallet } = useBalanceUpdate();
  const tonClient = useAtomValue(tonClientAtom);
  const loaded = !!tonClient;

  const min: ActionLimits = {
    stake: Big(1),
    unstake: Big(1).div(1000),
  };

  const address = rawAddress
    ? tonClient?.toUserFriendlyAddress(rawAddress)
    : undefined;

  const connected = !!address;
  const isLoading = wallet ? !balanceUpdated : false;

  if (!connected) {
    const getError = (action: Action, amount: Big): string | undefined => {
      if (amount.gte(min[action])) {
        return undefined;
      }

      return ERROR_MESSAGES.MIN_STAKE[action];
    };

    const connect = async () => {
      sendSimpleEvent("app_connect_wallet");
      tonClient?.tonConnectUI.openModal();
    };

    return {
      connected: false,
      getError,
      connect,
      min,
      max: { stake: Big(5000), unstake: Big(5000) },
      loaded,
      isLoading,
    };
  }

  const stakeBase = async (
    amount: Big,
    action: "stake" | "unstakeInstant" | "unstakeBestRate",
  ) => {
    if (!tonClient) {
      return false;
    }

    const tonstakers = tonClient.tonstakers;

    try {
      await tonstakers[action](amount.toNumber());
      await updateBalances();
      return true;
    } catch {
      return false;
    }
  };

  const stake = (amount: Big) => {
    return stakeBase(amount, "stake");
  };

  const unstake = async (amount: Big, bestRate: boolean) => {
    const action = bestRate ? "unstakeBestRate" : "unstakeInstant";

    amount = currency.convert(amount, "tsTON", "TON");

    return stakeBase(amount, action);
  };

  const userStakedTON = currency.convert(userStaked, "TON", "tsTON");

  const max: ActionLimits = {
    stake: userBalance,
    unstake: userStakedTON,
  };

  const getError = (action: Action, amount: Big): string | undefined => {
    if (amount.lt(min[action])) {
      return ERROR_MESSAGES.MIN_STAKE[action];
    }

    if (amount.gt(max[action])) {
      return ERROR_MESSAGES.NOT_ENOUGH[action];
    }

    return undefined;
  };

  return {
    rawAddress: rawAddress || "",
    address,
    shortAddress: getShortAddress(address),
    userBalance,
    userStaked: userStakedTON,
    tsTONBalance: userStaked,
    connected: true,
    stake,
    unstake,
    min,
    max,
    getError,
    disconnect: async () => tonClient?.tonConnectUI.disconnect(),
    updateBalances: triggerUpdate,
    nextRoundReward: currency.getProjectedEarnings(userStakedTON).perRound,
    loaded,
    isLoading,
  };
};
