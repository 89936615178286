export const ChevronIcon = (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="icon-chevron"
  >
    <path
      d="M3.5 1L8.5 6L3.5 11"
      stroke="currentColor"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
