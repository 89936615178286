import { FunctionalComponent } from "preact";

import { Text, TextButton } from "@/uikit";

import { ModalControls } from "@/uikit/Modal";

import "./style.css";

type TSTonModalProps = {
  controls: ModalControls;
};

export const TSTonModal: FunctionalComponent<TSTonModalProps> = (
  { controls },
) => {
  return (
    <div class="tston-modal">
      <div className="tston-description gap-vertical">
        <Text variant="h4" weight="semibold">
          What is tsTON?
        </Text>
        <Text>
          When staking, you receive tsTON as a receipt for your working Toncoins
          earning income.
          <br />
          <br />
          With tsTON, you can reclaim your funds, transfer it to friends, who
          can then start staking and get rewards based on their share.
          Additionally, you can exchange tsTON for other coins within TON
          ecosystem or receive it from friends.
        </Text>
      </div>
      <div className="tston-buttons">
        <TextButton onClick={controls.close}>Got it</TextButton>
      </div>
    </div>
  );
};
