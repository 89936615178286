import clsx from "clsx";
import { ComponentProps, FunctionalComponent } from "preact";

import { ClassNamed } from "@/types/classname";

import "./style.css";

export type TextProps = ClassNamed<{
  muted?: boolean;
  underline?: boolean;
  weight?: "normal" | "semibold" | "bold";
  variant?: "body" | "h3" | "h4" | "subtitle" | "caption";
  embed?: boolean;
  title?: string;
}> &
  ComponentProps<"span">;

export const Text: FunctionalComponent<TextProps> = (
  {
    variant,
    children,
    muted,
    underline,
    weight,
    embed,
    className,
    title,
    ...rest
  },
) => {
  const Component = embed ? "span" : "p";

  if (!variant && !embed) {
    variant = "body";
  }

  return (
    <Component
      className={clsx(
        "text",
        variant && `text-${variant}`,
        muted && "text-muted",
        underline && "text-underline",
        weight && `text-${weight}`,
        className,
      )}
      title={title}
      {...rest}
    >
      {children}
    </Component>
  );
};
