import { ComponentChildren, FunctionalComponent } from "preact";

import { Icon } from "@/icons";
import { Heading, Highlight, Text, TextButton } from "@/uikit";

import { IconKey } from "@/icons/sprite";
import { ModalControls } from "@/uikit/Modal";

import "./style.css";

export type OnboardingType = "general" | "earn";

type OnboardingProps = {
  controls: ModalControls;
  type: OnboardingType;
};

type OnboardingPoint = {
  text: ComponentChildren;
  icon: IconKey;
};

const getOnboardingPoints = (type: OnboardingType) => {
  const earnOnboardingPoints: OnboardingPoint[] = [
    {
      text: (
        <>
          <Text embed weight="semibold">
            up to <Highlight>4.5% APY</Highlight>
          </Text>{" "}
          for staking TON with Tonstakers
        </>
      ),
      icon: "clock",
    },
    {
      text: (
        <>
          <Text embed weight="semibold">
            up to <Highlight>11% APY</Highlight>
          </Text>{" "}
          in USDT for providing tsTON to DeFi protocols
        </>
      ),
      icon: "swap",
    },
    {
      text: (
        <>
          <Text embed weight="semibold">
            up to <Highlight>X% APR</Highlight>
          </Text>{" "}
          with Tonstakers Farm
        </>
      ),
      icon: "shield",
    },
  ];

  const generalOnboardingPoints: OnboardingPoint[] = [
    {
      text: "Instant deposits and withdrawals at any time",
      icon: "clock",
    },
    {
      text: "Rewards every 18 hours",
      icon: "swap",
    },
    {
      text: "Advanced security standards protocol",
      icon: "shield",
    },
    {
      text: "One of the biggest community on TON and 24/7 support service",
      icon: "globe",
    },
  ];

  const onboardingPoints: Record<OnboardingType, OnboardingPoint[]> = {
    earn: earnOnboardingPoints,
    general: generalOnboardingPoints,
  };

  return onboardingPoints[type];
};

type ContentType = "heading" | "button";

const content: Record<
  OnboardingType,
  Record<ContentType, ComponentChildren>
> = {
  earn: {
    heading: "Boost Your Yield x3 with Tonstakers",
    button: "See Boost Offers",
  },
  general: {
    heading: (
      <>
        Start earning up to <Highlight>4.5% APY</Highlight> with Tonstakers
      </>
    ),
    button: "Start earning",
  },
};

export const Onboarding: FunctionalComponent<OnboardingProps> = (
  { controls, type },
) => {
  const onboardingPoints = getOnboardingPoints(type);

  return (
    <div className="onboarding">
      <Heading level={1}>{content[type].heading}</Heading>
      <div className="onboarding-points gap-vertical">
        {onboardingPoints.map((point) => (
          <div className="onboarding-point" key={point.icon}>
            <Icon iconKey={point.icon} />
            <Text variant="h4">{point.text}</Text>
          </div>
        ))}
      </div>
      <div className="onboarding-buttons">
        <TextButton onClick={controls.close}>{content[type].button}</TextButton>
      </div>
    </div>
  );
};
