import { TextProps } from "../Text";
import { ComponentChildren, FunctionalComponent } from "preact";

import { Link, Loader, Text } from "@/uikit";

import "./style.css";

type RowSide = TextProps & {
  text: ComponentChildren;
  onClick?: () => void;
  showLoader?: boolean;
  href?: string;
};

export type RowData = {
  left: RowSide;
  right: RowSide;
  visible?: boolean;
};

const sides = ["left", "right"] as const;

type TableRowProps = { row: RowData };

export const TableRow: FunctionalComponent<TableRowProps> = ({ row }) => {
  return (
    <div class="table-row" data-visible={row.visible}>
      {sides.map((side) => {
        const { text, onClick, showLoader, href, ...props } = row[side];

        let content = (
          <Text key={side} {...props} data-side={side}>
            <Loader checkValue={!showLoader}>{text}</Loader>
          </Text>
        );

        if (onClick || href) {
          return (
            <Link onClick={onClick} href={href}>
              {content}
            </Link>
          );
        }

        return content;
      })}
    </div>
  );
};
