import { RefObject } from "preact";
import { StateUpdater, useRef } from "preact/hooks";

export type FocusControls = [boolean, StateUpdater<boolean>];

export const useFocus = (
  inputRef: RefObject<HTMLInputElement>,
  focusControls: FocusControls,
) => {
  const [focus, setFocusRaw] = focusControls;
  const focusLock = useRef(false);

  const setFocus = (value: boolean) => {
    if (focusLock.current) {
      return;
    }

    if (!focus && value) {
      inputRef.current?.focus();
    }

    if (focus && !value) {
      inputRef.current?.blur();
    }

    setFocusRaw(value);
  };

  const lockFocus = () => {
    focusLock.current = true;
  };

  const releaseFocus = () => {
    focusLock.current = false;
  };

  const addFocus = () => {
    setFocus(true);
  };

  const removeFocus = () => {
    setFocus(false);
  };

  const addLockFocus = () => {
    addFocus();
    lockFocus();
  };

  return {
    lockFocus,
    releaseFocus,
    addFocus,
    removeFocus,
    addLockFocus,
  };
};
