import { FunctionalComponent } from "preact";

import { Text } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import { formatAmount } from "@/utils/numbers";

type Rewards = { monthly: Big; yearly: Big };

const RewardColumn: FunctionalComponent<{ title: string; amount: Big }> = (
  { title, amount },
) => {
  const currency = useCurrency();

  const usdAmount = currency.convert(amount, "USD", "TON");

  return (
    <div className="reward-column">
      <Text variant="subtitle">{title}</Text>
      <Text
        variant="h3"
        weight="semibold"
        title={formatAmount({ amount, isPrecise: true })}
      >
        {formatAmount({ amount, fracDigits: 3 })}
      </Text>
      <Text
        variant="subtitle"
        muted
        title={formatAmount({
          amount: usdAmount,
          isPrecise: true,
          currency: "USD",
          tonSuffix: false,
        })}
      >
        {formatAmount({
          amount: usdAmount,
          fracDigits: 3,
          currency: "USD",
          tonSuffix: false,
        })}
      </Text>
    </div>
  );
};

export const RewardCard: FunctionalComponent<Rewards> = (
  { monthly, yearly },
) => {
  return (
    <div className="rewards">
      <RewardColumn title="Monthly" amount={monthly} />
      <RewardColumn title="Yearly" amount={yearly} />
    </div>
  );
};
