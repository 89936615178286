import { cycleEndAtom } from "@/state/api/blockchainData";

import "./style.css";

import { useAtomValue } from "jotai";
import { FunctionalComponent } from "preact";
import { useState } from "preact/hooks";

import { Loader } from "@/uikit";

import { useInterval } from "@/hooks/useInterval";
import { getRemainingHours } from "@/utils/cycle";

type RoundCountdownProps = {};

export const RoundCountdown: FunctionalComponent<RoundCountdownProps> = (
  {},
) => {
  // this is to rerender the component because cycleEnd is static but the amount of hours is changing with time
  const [_, setTicks] = useState(0);
  const cycleEnd = useAtomValue(cycleEndAtom);

  const remainingHours = Math.max(0, getRemainingHours(cycleEnd));

  useInterval(() => {
    setTicks((x) => x + 1);
  }, 60000);

  return (
    <>
      <Loader checkValue={remainingHours}>{remainingHours || 18}</Loader> hour
      {remainingHours === 1 ? "" : "s"}
    </>
  );
};
