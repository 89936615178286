import { FunctionalComponent } from "preact";

import { Text } from "@/uikit";

import "./style.css";

export const BulletSeparator: FunctionalComponent = () => (
  <Text embed muted className="bullet-separator">
    {" · "}
  </Text>
);
