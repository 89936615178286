import "./style.css";

import { FunctionalComponent } from "preact";

export type BoostColor = "purple" | "blue" | "cyan";

type Section = {
  color: BoostColor;
  active: boolean;
  size: number;
};

type SectionBarProps = {
  sections: Section[];
};

export const SectionBar: FunctionalComponent<SectionBarProps> = (
  { sections },
) => {
  return (
    <div class="section-bar">
      {sections.map((section, i) => (
        <div
          key={i}
          style={{ flexGrow: section.size }}
          data-color={section.color}
          data-active={section.active}
        />
      ))}
    </div>
  );
};
