const TMA = Telegram?.WebApp;

if (TMA) {
  TMA.expand();
  TMA.setBackgroundColor("#1b2025");
}

export const setHeaderColor = (color: string) => {
  if (TMA) {
    TMA.setHeaderColor(color);
  }
};
