import Big from "big.js";
import { FunctionalComponent } from "preact";

import { HelpButton, TSTonModal } from "@/components";
import { Text } from "@/uikit";

import { useCurrency } from "@/hooks/useCurrency";
import { useModal } from "@/uikit/Modal";
import { formatAmount } from "@/utils/numbers";

type ExchangeYieldProps = { amount: Big };

const placeholder = Big(123456789).div(10000000);

export const ExchangeYield: FunctionalComponent<ExchangeYieldProps> = (
  { amount },
) => {
  const currency = useCurrency();
  const modalState = useModal();

  const tsTONAmount = currency.convert(amount, "tsTON", "TON");

  return (
    <Text
      embed
      title={formatAmount({
        amount: tsTONAmount,
        currency: "tsTON",
        fracDigits: 2,
        isPrecise: true,
        tonSuffix: false,
      })}
    >
      <HelpButton onClick={() => modalState.addLayer(TSTonModal)}>
        <span>
          {formatAmount({
            amount: currency.loaded ? tsTONAmount : undefined,
            placeholder,
            currency: "tsTON",
            fracDigits: 2,
            isPrecise: false,
            tonSuffix: false,
          })}{" "}
        </span>
      </HelpButton>
    </Text>
  );
};
