import { Wallet } from "@tonconnect/ui";
import Big from "big.js";
import { atom } from "jotai";

import { getAddress } from "@/utils/address";

export const walletAtom = atom<Wallet | null>(null);
export const addressAtom = atom<string | undefined>((get) =>
  getAddress(get(walletAtom)),
);

export const userBalanceAtom = atom<Big>(Big(0));
export const userStakedAtom = atom<Big>(Big(0));
