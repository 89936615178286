import { useSetAtom } from "jotai";

import { scrolledAtom } from "@/state/layout";

import { useEventListener } from "./useEventListener";

export const useScrollState = () => {
  const setScrolled = useSetAtom(scrolledAtom);

  useEventListener(window, "scroll", () => {
    const scrollAmount = window.scrollY;
    setScrolled(scrollAmount > 0);
  });
};
